global.process = global.process || {
  env: {},
};

export const ENV = {
  API_HOST: process.env.API_HOST || 'https://staging-platform.lower.com',
  APPLICATION_INSIGHTS_CONNECTION_STRING: process.env.APPLICATION_INSIGHTS_CONNECTION_STRING || 'fakestring',
  DATADOG_APPLICATION_ID: process.env.DATADOG_APPLICATION_ID || 'fake',
  DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN || 'fake',
  DATADOG_ENV: process.env.DATADOG_ENV || 'local',
  DATADOG_SAMPLE_RATE: parseInt(process.env.DATADOG_SAMPLE_RATE || '0', 10),
  ENVIRONMENT: process.env.NODE_ENV || 'local',
  FRONTEND_SHA: process.env.FRONTEND_SHA || 'development',
  GOOGLE_ANALYTICS_ID: process.env.GOOGLE_ANALYTICS_ID || 'fakeid',
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY || 'AIzaSyB77q_TBJ48EaKwlizomtwlypyJzDPUdHQ',
  NEW_BACKEND_API_HOST: process.env.NEW_BACKEND_API_HOST || 'http://localhost:3000',
  NEW_BACKEND_ENABLED: process.env.NEW_BACKEND_ENABLED || 'false',
};
