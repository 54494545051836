import styled from 'styled-components';
import { responsiveStyleFromTheme } from '@lightspeed/styles/utils/theme-utils';

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  flex-direction: row;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 18px 0;
  ${responsiveStyleFromTheme({
    desktop: {
      margin: '24px 0',
    },
  })}
`;
