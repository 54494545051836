import { createContext, ReactNode, useContext } from 'react';
import {
  useMortgageApplicationState,
  MortgageApplicationState,
  MortgageApplicationStore,
  initialApplication,
} from './use-mortgage-application-state';

const MortgageContext = createContext<MortgageApplicationState>({
  mortgageApplication: {
    ...initialApplication,
  },
  updateMortgageApplication: () => {
    throw new Error('updateMortgageApplication cannot be used before it is set');
  },
});

export function MortgageContextProvider({ children, overrides }: { children: ReactNode|ReactNode[], overrides: Partial<MortgageApplicationStore> }) {
  return (
    <MortgageContext.Provider value={useMortgageApplicationState(overrides)}>
      {children}
    </MortgageContext.Provider>
  );
}

export function useMortgageApplication() {
  return useContext(MortgageContext);
}
