import cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { getCookieOptions } from '@lightspeed/utils/get-cookie-options';

const key = 'lo';

export function removeLoanOfficerToken(): void {
  cookies.remove(key, getCookieOptions());
}

export function getLoanOfficerToken(): string | null {
  return cookies.get(key) || null;
}

export function setLoanOfficerToken(token: string): void {
  cookies.set(key, token, getCookieOptions());
}

function getInitialToken(): string | null {
  const url = new URL(window.location.href);

  const queryToken = url.searchParams.get(key);

  return queryToken || getLoanOfficerToken();
}

export function useLoanOfficerToken() {
  const [token] = useState<string | null>(() => getInitialToken());

  useEffect(() => {
    const url = new URL(window.location.href);

    const queryToken = url.searchParams.get(key);

    if (queryToken) {
      setLoanOfficerToken(queryToken);
    }
  }, []);

  return token;
}
