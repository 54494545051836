import { Component, ReactNode } from 'react';
import { Error as ErrorPage } from '@lightspeed/components/ui/pages/error/error';
import { appInsights } from '@lightspeed/utils/app-insights';

interface ErrorBoundaryState { hasError: boolean }

interface Props {
  storybook?: boolean;
  children: ReactNode;
}

export class ErrorBoundary extends Component<Props, ErrorBoundaryState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    storybook: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error) {
    const { storybook } = this.props;
    if (!storybook) {
      appInsights.trackException({
        exception: error,
      });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children, storybook } = this.props;

    if (hasError && !storybook) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    }

    return children;
  }
}
