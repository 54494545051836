import styled from 'styled-components';
import { IconCircle, Button, Heading, Paragraph } from '@lower/core-components';
import { MapPin } from '@lower/icons';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

export function UnsupportedStates() {
  const { mortgageApplication } = useMortgageApplication();

  return (
    <BasePageTemplate>
      <Header />
      <UnsupportStatesContainer>
        <IconCircle
          icon={MapPin}
          background={'rgba(0, 28, 219, 0.1);'}
          circleSize={'100px'}
          altText={'Map Icon'}
        />
        <TitleHeader >It looks like we’re not in your area yet.</TitleHeader>
        <CopyHeader>We’re still getting set up in {mortgageApplication.propertyState}. We’ll send you an email to let you know when we’re in your neighborhood.</CopyHeader>
        <CtaMobileCard>
          <CtaCopy>Save for a home faster.</CtaCopy>
          <Button
            aria-label={'Get the app'}
            variant={'link'}
          >Get the app &gt;
          </Button>
        </CtaMobileCard>
      </UnsupportStatesContainer>
    </BasePageTemplate>
  );
}
const UnsupportStatesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 70px;
  ${(props) => props.theme.media.desktop} {
    padding-top: 70px;
  }
`;

const TitleHeader = styled(Heading)`
  display: flex;  
  padding-bottom: 16px;
  padding-top: 24px;

  /* font styles mobile */
  font-family: ${(props) => props.theme.Headers.h3};
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #1D1D23;
  ${(props) => props.theme.media.desktop} {
    /* font styles desktop */
    font-family: ${(props) => props.theme.Headers.h1};
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 72px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #1D1D23;
  }
`;

const CopyHeader = styled(Paragraph)`
  display: flex;
  width: 100%;
  padding-bottom: 60px;

  /* font styles mobile */
  font-family: ${(props) => props.theme.Body.regular};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  /* Neutral/Ash */
  color: #6A6B76;
  ${(props) => props.theme.media.desktop} {
    /* font styles desktop */
    font-family: ${(props) => props.theme.Body.regular};
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* Neutral/Ash */
    color: #6A6B76;
  }
`;

const CtaMobileCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  border-radius: 16px;
  box-shadow: 0 24px 48px rgba(23, 23, 47, 0.1);
  padding-top: 35px;
  padding-bottom: 30px;
  padding-left:20px;
  padding-right: 20px;
  ${(props) => props.theme.media.desktop} {
    display: none;
  }
`;

const CtaCopy = styled.div`
  display: flex;
  padding-bottom: 20px;

  /* font styles mobile */
  font-family: ${(props) => props.theme.Headers.h4};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #1D1D23;
  ${(props) => props.theme.media.desktop} {
    /* font styles desktop */
    font-family: ${(props) => props.theme.Headers.h3};
    font-size: 25px;
  }
`;
