import React from 'react';
import { ThemeProvider } from 'styled-components';
import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { MortgageContextProvider } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { PersonalizedLoanStore } from '@lightspeed/contexts/personalized-loan-context/use-personalized-loan-state';
import { PersonalizedLoanContextProvider } from '@lightspeed/contexts/personalized-loan-context/personalized-loan-context';
import { GlobalStyle } from '@lightspeed/styles/global-style';
import { theme } from '@lightspeed/styles/primary/theme';
import { RQWRatesContextProvider, RQWRates } from '@lightspeed/contexts/rqw-rates-context/rqw-rates-context';
import { ErrorBoundary } from './error-boundary';

export interface AppProviderProps {
  children?: React.ReactNode;
  mortgageContextOverride?: Partial<MortgageApplicationStore>,
  personalizedLoanContextOverride?: Partial<PersonalizedLoanStore>,
  rqwRatesContextOverride?: null|RQWRates,
  storybook?: boolean,
}

export function AppProvider(
  {
    children,
    mortgageContextOverride = {},
    personalizedLoanContextOverride = {},
    rqwRatesContextOverride = null,
    storybook = false,
  }: AppProviderProps,
) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ErrorBoundary storybook={storybook}>
        <RQWRatesContextProvider overrides={rqwRatesContextOverride}>
          <MortgageContextProvider overrides={mortgageContextOverride}>
            <PersonalizedLoanContextProvider overrides={personalizedLoanContextOverride}>
              {children}
            </PersonalizedLoanContextProvider>
          </MortgageContextProvider>
        </RQWRatesContextProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

