import URI from 'urijs';
import { STATE_ABBREVIATION_TO_NAME } from '@lightspeed/models/us-states';

export interface RQWParams {
  fromRateQuery?: string;
  loanTerm?: 'fifteen'|'twenty'|'thirty';
  propertyType?: string;
  propertyResidenceType?: string;
  propertyState?: string;
  propertyZipCode?: string;
  rateFor15YearTerm?: number;
  rateFor20YearTerm?: number;
  rateFor30YearTerm?: number;
  aprFor15YearTerm?: number;
  aprFor20YearTerm?: number;
  aprFor30YearTerm?: number;
  discountPointsFor15YearTerm?: number;
  discountPointsFor20YearTerm?: number;
  discountPointsFor30YearTerm?: number;
  monthlyPaymentFor15YearTerm?: number;
  monthlyPaymentFor20YearTerm?: number;
  monthlyPaymentFor30YearTerm?: number;
  propertyDownPayment?: string;
  propertyPurchasePrice?: string;
  propertyCashOut?: string;
  propertyHomeValue?: string;
  propertyMortgageBalance?: string;
  loanAmount?: number;
  reasonForRefinance?: 'lowerPayment' | 'cashOut' | 'lessInterest' | '';
  ltv?: number;
}

interface QueryRatesParams {
  aprFor15YearTerm: string;
  aprFor20YearTerm: string;
  aprFor30YearTerm: string;
  discountPointsFor15YearTerm: string;
  discountPointsFor20YearTerm: string;
  discountPointsFor30YearTerm: string;
  fromRateQuery: string;
  purchasePrice: string;
  creditScore: string;
  county: string;
  state: string;
  zip: string;
  downPayment: string;
  loanPurpose: string;
  loanAmount: string;
  loanTerm: string;
  ltv: string;
  monthlyPaymentFor15YearTerm: string;
  monthlyPaymentFor20YearTerm: string;
  monthlyPaymentFor30YearTerm: string;
  mortgageBalance: string;
  homeValue: string;
  cashOut: string;
  propertyUse: 'PrimaryResidence'|'SecondHome'|'InvestmentProperty';
  propertyType: 'SingleFamily'|'Condo'|'Duplex'|'Triplex'|'Fourplex';
  rateFor15YearTerm: string;
  rateFor20YearTerm: string;
  rateFor30YearTerm: string;
  reasonForRefinance: string;
  zipcode: string;
}

const refinanceMap: Record<string, '' | 'cashOut' | 'lowerPayment' | 'lessInterest'> = {
  '': '',
  cashOut: 'cashOut',
  lessInterest: 'lessInterest',
  lowerPayment: 'lowerPayment',
};

const termMap: Record<string, 'fifteen'|'twenty'|'thirty'> = {
  15: 'fifteen',
  20: 'twenty',
  30: 'thirty',
};

const usageMap: Record<'PrimaryResidence'|'SecondHome'|'InvestmentProperty', 'Primary Residence'|'Secondary Residence'|'Investment'> = {
  InvestmentProperty: 'Investment',
  PrimaryResidence: 'Primary Residence',
  SecondHome: 'Secondary Residence',
};

const propertyTypeMap: Record<'SingleFamily'|'Condo'|'Duplex'|'Triplex'|'Fourplex', string> = {
  Condo: 'Condo',
  Duplex: 'Duplex',
  Fourplex: 'Fourplex',
  SingleFamily: 'Single Family',
  Triplex: 'Triplex',
};

type Transformers = {
  [P in keyof QueryRatesParams]?: (v: QueryRatesParams[P]) => Partial<RQWParams>;
};

const transformers: Transformers = {
  aprFor15YearTerm: (v) => ({
    aprFor15YearTerm: Number(v),
  }),
  aprFor20YearTerm: (v) => ({
    aprFor20YearTerm: Number(v),
  }),
  aprFor30YearTerm: (v) => ({
    aprFor30YearTerm: Number(v),
  }),
  cashOut: (v) => ({
    propertyCashOut: v,
  }),
  discountPointsFor15YearTerm: (v) => ({
    discountPointsFor15YearTerm: Number(v),
  }),
  discountPointsFor20YearTerm: (v) => ({
    discountPointsFor20YearTerm: Number(v),
  }),
  discountPointsFor30YearTerm: (v) => ({
    discountPointsFor30YearTerm: Number(v),
  }),
  downPayment: (v) => ({
    propertyDownPayment: v,
  }),
  fromRateQuery: (v) => ({
    fromRateQuery: v,
  }),
  homeValue: (v) => ({
    propertyHomeValue: v,
  }),
  loanAmount: (v) => ({
    loanAmount: Number(v),
  }),
  loanTerm: (v) => ({
    loanTerm: termMap[v],
  }),
  ltv: (v) => ({
    ltv: Number(v),
  }),
  monthlyPaymentFor15YearTerm: (v) => ({
    monthlyPaymentFor15YearTerm: Number(v),
  }),
  monthlyPaymentFor20YearTerm: (v) => ({
    monthlyPaymentFor20YearTerm: Number(v),
  }),
  monthlyPaymentFor30YearTerm: (v) => ({
    monthlyPaymentFor30YearTerm: Number(v),
  }),
  mortgageBalance: (v) => ({
    propertyMortgageBalance: v,
  }),
  propertyType: (v) => ({
    propertyType: propertyTypeMap[v],
  }),
  propertyUse: (v) => ({
    propertyResidenceType: usageMap[v],
  }),
  purchasePrice: (v) => ({
    propertyPurchasePrice: v,
  }),
  rateFor15YearTerm: (v) => ({
    rateFor15YearTerm: Number(v),
  }),
  rateFor20YearTerm: (v) => ({
    rateFor20YearTerm: Number(v),
  }),
  rateFor30YearTerm: (v) => ({
    rateFor30YearTerm: Number(v),
  }),
  reasonForRefinance: (v) => ({
    reasonForRefinance: refinanceMap[v],
  }),
  state: (v) => ({
    propertyState: STATE_ABBREVIATION_TO_NAME.get(v),  // enum check
  }),
  zipcode: (v) => ({
    propertyZipCode: v,
  }),
};

const isFunction = (possibleFn: unknown): possibleFn is (v: unknown) => Partial<RQWParams> => typeof possibleFn === 'function';

export function getRateQueryWidgetParams(url: string): RQWParams {
  const paramsFromUrl = new URI(url).query(true);

  const params: RQWParams = {};

  Object.keys(paramsFromUrl).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const value = paramsFromUrl[key];

    // @ts-expect-error TODO: Mike, can't figure this one out
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const transformerFunction = transformers[key];

    if (isFunction(transformerFunction)) {
      Object.assign(params, transformerFunction(value));
    }
  });

  return params;
}
