import styled from 'styled-components';
import { ReactNode } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.theme.space[4]};
  min-height: 100vh;
  box-sizing: border-box;
`;

const Content = styled.section`
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: ${(props) => props.theme.contentWidth};
`;

export interface GenericTemplateProps {
  children: ReactNode;
}

export const GenericTemplate = ({ children, ...props }: GenericTemplateProps) => (
  <Wrapper {...props}>
    <Content>{children}</Content>
  </Wrapper>
);

