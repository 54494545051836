import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { PersonalizedLoanStore } from '@lightspeed/contexts/personalized-loan-context/use-personalized-loan-state';
import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { isUnsupportedMarket } from '@lightspeed/utils/get-is-unsupported-market';
import { RoutingMachine, ROUTING_EVENTS, RoutesPriorToMinimumInfo, ROUTES } from './routing-machine';

export function useNextRoute(mortgageApplication: MortgageApplicationStore, personalizedLoanStore: PersonalizedLoanStore = {
  loanProducts: [],
  passedFilters: false,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  // if a user hits a page with an obviously incomplete application context, send them back to basic info to start over.
  if (
    !mortgageApplication.borrowerFirstName
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    && Object.values(ROUTES).includes(location.pathname as ROUTES)
    && !RoutesPriorToMinimumInfo.has(location.pathname)
  ) {
    setTimeout(() => navigate(ROUTES.LANDING), 0); // timeout allows us to bypass some router safety features
  }

  return useCallback(() => {
    const state = RoutingMachine
      .withContext({
        applicationType: mortgageApplication.applicationType,
        borrowerContractStatus: mortgageApplication.borrowerContractStatus,
        hasRates: personalizedLoanStore.loanProducts.length > 0,
        isUnsupportedMarket: isUnsupportedMarket(mortgageApplication.propertyState),
      })
      .transition(location.pathname, ROUTING_EVENTS.NEXT);

    navigate(state.value);
  }, [
    mortgageApplication.applicationType,
    mortgageApplication.borrowerContractStatus,
    personalizedLoanStore.loanProducts.length,
    location,
    navigate,
    mortgageApplication.propertyState,
  ]);
}
