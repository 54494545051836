import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { appInsights } from '@lightspeed/utils/app-insights';
import { submitQuotingApplication } from '@lightspeed/api/services/new-backend/lending/submitQuotingApplication';

export function useSubmitQuotingApplication() {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();

  const submit = async () => {
    try {
      const response = await submitQuotingApplication(mortgageApplication.leadId);

      if (response.status === 202) {
        const data = await response.json();
        updateMortgageApplication('quotingApplicationSubmissionId', data.quotingApplicationSubmissionId);
        return data.quotingApplicationSubmissionId;
      }
      throw new Error('Could not submit application');
    } catch {
      const error =  new Error('Couldnt create update quoting application');
      appInsights.trackException({
        exception: error,
      });
      throw error;
    }
  };

  return submit;
}
