import styled from 'styled-components';
import { useAnalytics } from '@lower/analytics';
import { Heading } from '@lower/core-components';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { BasePageTemplate   } from '@lightspeed/components/ui/templates';

export function Error() {
  useAnalytics('v2/error');

  return (
    <BasePageTemplate>
      <Header />
      <PageWrapper>
        <Heading
          style={{
            textAlign: 'center',
          }}
        >
          Something went wrong!
        </Heading>
        <Heading
          element={'p'}
          style={{
            textAlign: 'center',
          }}
        >
          Our engineering team has been notified and will take a look.
        </Heading>
      </PageWrapper>
    </BasePageTemplate>
  );
}

const PageWrapper = styled.div`
  width: 100%;
`;
