import { useState } from 'react';
import { IndividualPersonalizedLoan } from '@lightspeed/api/services/personalizedLoanProducts/bestavailable';

export type PersonalizedLoanStore = {
  loanProducts: Array<IndividualPersonalizedLoan>,
  passedFilters: boolean,
};

export interface PersonalizedLoanState {
  personalizedLoanOptions: PersonalizedLoanStore,
  updatePersonalizedLoan: (updatedState: Partial<PersonalizedLoanStore>) => void
}

const initialLoan: PersonalizedLoanStore = {
  loanProducts: [],
  passedFilters: false,
};

export function usePersonalizedLoanState(overrides: Partial<PersonalizedLoanStore>) {
  const [loanProducts, setLoanProducts] = useState<PersonalizedLoanStore>({
    ...initialLoan,
    ...overrides,
  });

  const update = (updatedState: Partial<PersonalizedLoanStore>) => {
    setLoanProducts((currentState) => ({
      ...currentState,
      ...updatedState,
    }));
  };

  return {
    personalizedLoanOptions: loanProducts,
    updatePersonalizedLoan: update,
  };
}
