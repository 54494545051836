import React, { forwardRef } from 'react';
import { TextInput } from '..';
import { TextInputProps } from '../text-input/text-input';

export type NumberInputProps = TextInputProps & JSX.IntrinsicElements['input'] & {
  type?: never;
  as?: React.ElementType;
};

export const NumberInput = forwardRef(
  ({
    type,
    onChange,
    ...props
  }: NumberInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
  ) => (
    <TextInput
      ref={ref}
      type={'text'}
      inputMode={'numeric'}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
        onChange && onChange(e);
      }}
      {...props}
    />
  ),
);
