import styled, { css } from 'styled-components';
import { ThemedProps } from '../../styles/utils/theme-utils';

export const CheckBox = styled.input`
  appearance: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid ${(props: ThemedProps) => props.theme.colors.gray[0]};
  cursor: pointer;
  vertical-align: middle;
  &:checked {
    border: 5px solid ${(props: ThemedProps) => props.theme.colors.blue[0]};
  }

  ${(props: ThemedProps & JSX.IntrinsicElements['input']) => !props.checked && css`
    &:hover {
      background: ${props.theme.colors.gray[0]};
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin: 2px;
        background: ${props.theme.colors.gray[1]};
      }
    }
  `}
`;
