import { date, number, object, string } from 'yup';
import { LoanPurpose } from '@lower-web-api/generated';
import { STATE_OPTIONS } from '@lightspeed/models/us-states';
import { CONTRACT_STATUSES } from '@lightspeed/models/contract-statuses';
import { EmploymentStatus } from '@lightspeed/types/income';
import { coborrowerEmploymentStatuses, employmentStatuses } from '@lightspeed/models/employment-status';

const today = new Date();

const refinanceOrHelocApplicationType = (applicationType: string) => [LoanPurpose.Refinance, LoanPurpose.Heloc]
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  .includes(applicationType as LoanPurpose.Refinance | LoanPurpose.Heloc);
const notEmpty = (value: unknown) => value !== '';
const allTruthy = (...args: unknown[]) => !args.some((arg) => !arg);

export const mortgageApplicationSchema = object().shape({
  borrowerAmountInMutualFunds: number().min(0).label('Amount in mutual funds')
    .when('applicationType', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),

  borrowerAmountInSavings: number().min(1).label('Amount in savings')
    .when('applicationType', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),

  borrowerCity: string().required().label('City'),

  borrowerContractStatus: string().oneOf(Object.values(CONTRACT_STATUSES), 'Contract Status is required')
    .when('applicationType', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),

  borrowerCounty: string().required().label('County'),

  borrowerDateOfBirth: date().required().typeError('ex: 03/09/1994')
    .min(new Date('01/01/1885'), 'Must be less than 115 years old')
    .max(new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()), 'Must be 18 years or older')
    .label('Date of Birth'),

  borrowerEmail: string().email().required().label('Email'),

  borrowerEmployer: string().required().label('Employer name'),

  borrowerEmploymentStatus: string().oneOf(employmentStatuses,  'Employment Status is required').required(),

  borrowerFirstName: string().required().label('First name'),

  borrowerFirstTimeHomeBuyer: string().oneOf(['Yes', 'No'])
    .when('applicationType', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),

  borrowerLastName: string().required().label('Last name'),

  borrowerOtherIncomePerYear: number().min(0).required().label('Other income per year')
    .when('borrowerYearlySalary', {
      is: (salary: string) => salary === '' || parseInt(salary, 10) < 1,
      then: number().min(1).required().label('Combined yearly income'),
    }),

  borrowerPhoneNumber: string().matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'requires valid phone number (e.g. 999-999-9999)').required().label('Phone Number'),

  borrowerSocialSecurityNumber: string().matches(/^\d{3}-?\d{2}-?\d{4}$/,  'requires valid social security number').required().label('Social Security Number'),

  borrowerState: string().oneOf(STATE_OPTIONS.map((kvp) => kvp.value), 'State is required').required().label('State'),

  borrowerStreetAddress: string().required().label('Street address'),

  borrowerTitle: string().required().label('Title'),

  borrowerYearlySalary: number().min(0).required().label('Annual Gross Salary')
    .when('borrowerOtherIncomePerYear', {
      is: (otherIncome: string) => otherIncome === '' || parseInt(otherIncome, 10) < 1,
      then: number().min(1).required().label('Combined yearly income'),
    }),

  borrowerYearsAtAddress: number().min(0).max(99, 'Years at Current Address must be less than or equal to 99 years').required().typeError('Years at Current Address is required'),

  borrowerYearsAtCompany: number().min(0).max(99, 'Years at company must be less than or equal to 99 years').required().label('Years at company'),

  borrowerZipCode: string().matches(/^\d{5}$/g, 'requires valid zip code').required().label('Zip code'),

  coBorrowerAmountInMutualFunds: number().nullable().transform((curr: string, orig: string) => (orig === '' ? null : curr)) // transform required to handle default value of '' even when optional
    .when(['coBorrowerFirstName'], {
      is: notEmpty,
      then: number().min(0).required(),
    }),

  coBorrowerAmountInSavings: number().nullable().transform((curr: string, orig: string) => (orig === '' ? null : curr)) // transform required to handle default value of '' even when optional
    .when(['coBorrowerFirstName'], {
      is: notEmpty,
      then: number().min(0).required(),
    }),

  coBorrowerCity: string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: allTruthy,
      then: string().label('City').required(),
    }),

  coBorrowerCounty: string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: allTruthy,
      then: string().label('County').required(),
    }),

  coBorrowerDateOfBirth: date().nullable().transform((curr: string, orig: string) => (orig === '' ? null : curr)) // transform required to handle default value of '' even when optional
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: date().required().typeError('ex: 03/09/1994')
        .min(new Date('01/01/1885'), 'Must be less than 115 years old')
        .max(new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()), 'Must be 18 years or older')
        .label('Date of Birth'),
    }),

  coBorrowerEmail: string()
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: string().email().label('Email').required(),
    }),

  coBorrowerEmployer: string()
    .when(['coBorrowerFirstName', 'coBorrowerEmploymentStatus'], {
      is: (coBorrowerFirstName: string, employmentStatus: string) =>
        coBorrowerFirstName !== ''
        && employmentStatus !== EmploymentStatus.UNEMPLOYED,
      then: string().required(),
    }),

  coBorrowerEmploymentStatus: string()
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: string().oneOf(coborrowerEmploymentStatuses, 'Employment Status is required').required(),
    }),

  coBorrowerFirstName: string()
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: string().label('First Name').required(),
    }),

  coBorrowerLastName: string()
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: string().label('Last Name').required(),
    }),

  coBorrowerOtherIncomePerYear: number().nullable().transform((curr: string, orig: string) => (orig === '' ? null : curr)) // transform required to handle default value of '' even when optional
    .when(['coBorrowerFirstName'], {
      is: notEmpty,
      then: number().min(0).required(),
    }),

  coBorrowerPhoneNumber: string()
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: string().matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'requires valid phone number (e.g. 999-999-9999)').label('Phone Number').required(),
    }),

  coBorrowerSocialSecurityNumber: string()
    .when(['$hasCoBorrower'], {
      is: allTruthy,
      then: string().matches(/^\d{3}-?\d{2}-?\d{4}$/,  'requires valid social security number').label('Social Security Number').required(),
    }),

  coBorrowerState: string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: allTruthy,
      then: string().oneOf(STATE_OPTIONS.map((kvp) => kvp.value), 'State is required').required(),
    }),

  coBorrowerStreetAddress: string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: allTruthy,
      then: string().label('Street Address').required(),
    }),

  coBorrowerTitle: string()
    .when(['coBorrowerFirstName', 'coBorrowerEmploymentStatus'], {
      is: (coBorrowerFirstName: string, employmentStatus: string) => coBorrowerFirstName !== '' && employmentStatus !== EmploymentStatus.UNEMPLOYED,
      then: string().label('Title').required(),
    }),

  coBorrowerYearlySalary: number().nullable().transform((curr: string, orig: string) => (orig === '' ? null : curr)) // transform required to handle default value of '' even when optional
    .when(['coBorrowerFirstName'], {
      is: notEmpty,
      then: number().min(0).required(),
    }),

  coBorrowerYearsAtCompany: number().nullable().transform((curr: string, orig: string) => (orig === '' ? null : curr)) // transform required to handle default value of '' even when optional
    .when(['coBorrowerFirstName', 'coBorrowerEmploymentStatus'], {
      is: (coBorrowerFirstName: string, employmentStatus: string) => coBorrowerFirstName !== '' && employmentStatus !== EmploymentStatus.UNEMPLOYED,
      then: number().min(0).max(99, 'Years at company must be less than or equal to 99 years').required(),
    }),

  coBorrowerZipCode: string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: allTruthy,
      then: string().matches(/^\d{5}$/g, 'requires valid zip code').label('Zip Code').required(),
    }),

  discountPoints: string().required(),

  loanTerm: string().oneOf(['fifteen', 'twenty', 'thirty']).required(),

  propertyCashOut: number().min(0).typeError('Cash out value is required')
    .when('applicationType', {
      is: refinanceOrHelocApplicationType,
      then: (schema) => schema.required(),
    }),

  propertyCity: string().label('City').required(),

  propertyCounty: string().label('County').required(),

  propertyDownPayment: number().min(1)
    .when('applicationType', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),

  propertyDownPaymentPartiallyGift: string().oneOf(['Yes', 'No'])
    .when('applicationType', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),

  propertyHomeValue: number().min(1).typeError('Home Value is required')
    .when('applicationType', {
      is: refinanceOrHelocApplicationType,
      then: (schema) => schema.required(),
    }),

  propertyMortgageBalance: number().min(1).typeError('Outstanding Mortgage Balance is required')
    .when('applicationType', {
      is: refinanceOrHelocApplicationType,
      then: (schema) => schema.required(),
    }),

  propertyPurchasePrice: number().min(1)
    .when('applicationType', {
      is: LoanPurpose.Purchase,
      then: (schema) => schema.required(),
    }),

  propertyResidenceType: string().oneOf(['Primary Residence', 'Secondary Residence', 'Investment'], 'Residence Type is required').required().label('Residence Type'),

  propertyState: string()
    .oneOf(STATE_OPTIONS.map((kvp) => kvp.value), ({ value }: { value: string }) => 'State is required')
    .required('State is required')
    .label('State'),

  propertyStreetAddress: string()
    .when(['applicationType', 'borrowerContractStatus'], {
      is: (applicationType: string, borrowerContractStatus: string) => (refinanceOrHelocApplicationType(applicationType) || borrowerContractStatus === CONTRACT_STATUSES.inContract),
      then: string().label('Street Address').required(),
    }),

  propertyType: string().oneOf(['Single Family', 'Condo', 'Duplex', 'Triplex', 'Fourplex'], 'Property Type is required').required(),

  propertyZipCode: string().matches(/^\d{5}$/g, 'requires valid zip code').label('Zip Code').required(),

  quotingApplicationSubmissionId: string(),
}, [['borrowerYearlySalary', 'borrowerOtherIncomePerYear']]);
