import { ErrorResponse, QuotingApplicationSubmissionCreated } from '@lower-web-api/generated';
import { ENV } from '@lightspeed/environment';
import { fetch, TypedResponse } from '@lightspeed/utils/fetch';

export function submitQuotingApplication(quotingApplicationId: string): Promise<SubmitQuotingApplicationResponse> {
  return fetch<SubmitQuotingApplicationResponse>(
    `${ENV.NEW_BACKEND_API_HOST}/api/lending/quoting_applications/${quotingApplicationId}/submissions`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );
}

type NotFoundErrorResponseInterface = TypedResponse<ErrorResponse, 404>;
type SubmissionCreatedInterface = TypedResponse<QuotingApplicationSubmissionCreated, 202>;

export type SubmitQuotingApplicationResponse = NotFoundErrorResponseInterface | SubmissionCreatedInterface;
