import React, { FocusEventHandler } from 'react';
import InputMask from 'react-input-mask';
import { TextInput } from '..';

export interface DateInputProps {
  mask?: string;
  minDate?: string;
  maxDate?: string;
  hasError?: boolean;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  name?: string;
}

export const DateInput = ({
  mask = '99/99/9999',
  value,
  name,
  onChange,
  hasError,
  placeholder = '12/30/1990',
  disabled = false,
  onFocus,
}: DateInputProps) => (
  <InputMask
    name={name}
    mask={mask}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    disabled={disabled}
    onFocus={onFocus}
  >
    {(inputProps: DateInputProps) => (
      <TextInput
        {...inputProps}
        disabled={disabled}
        $hasError={hasError}
        inputMode={'numeric'}
        autoComplete={'none'}
      />
    )}
  </InputMask>
);

