import styled from 'styled-components';
import cx from 'classnames';
import { compose, MarginProps, margin } from 'styled-system';
import { ReactNode } from 'react';
import { ThemedProps, withVariations } from '../../styles/utils/theme-utils';

export type PillProps = {
  children?: ReactNode
  large?: boolean;
  testId?: string;
  variant?: 'blue' | 'teal' | 'purple' | 'green';
} & MarginProps;

export const Pill = styled.div.attrs(
  ({ variant, ...props }: PillProps) =>
    ({
      ...props,
      className: cx(
        'Pill',
        variant && `--${ variant }`,
        props.large && '--large',
      ),
      'data-test-id': props.testId,
    }),
)<PillProps>`
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  background: rgba(187, 187, 187, 0.1);
  border-radius: 1000px;

  ${ (props: ThemedProps) => props.theme.Label.bold };
  ${ (props: ThemedProps) => withVariations(props.theme.pill.variations) };
  ${ compose(margin) };
`;
