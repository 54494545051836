import React from 'react';
import styled from 'styled-components';
import { Heading, IconCircle } from '@lower/core-components';
import Bolt from '@lightspeed/assets/icons/bolt.svg';
import OpenHouse from '@lightspeed/assets/icons/open-house.svg';
import Star from '@lightspeed/assets/icons/star.svg';
import { responsiveStyleFromTheme } from '@lightspeed/styles/utils/theme-utils';

export const HelocSidebarBlurb = () => (
  <div>
    <HeadingWrapper>
      <Heading
        element={'h3'}
        maxWidth={'232px'}
      >
        Access up to 95% of your equity.
      </Heading>
    </HeadingWrapper>
    <BlurbSection>
      <Blurb>
        <BlurbIconContainer>
          <IconCircle
            background={'rgba(255, 184, 47, 0.1)'}
            icon={Bolt}
          />
        </BlurbIconContainer>
        <BlurbText>
          Pay off your debt fast
        </BlurbText>
      </Blurb>
      <Blurb>
        <BlurbIconContainer>
          <IconCircle
            background={'rgba(62, 146, 247, 0.1)'}
            icon={OpenHouse}
          />
        </BlurbIconContainer>
        <BlurbText>
          Renovate your home
        </BlurbText>
      </Blurb>
      <Blurb>
        <BlurbIconContainer>
          <IconCircle
            background={'rgba(0, 182, 122, 0.1)'}
            icon={Star}
          />
        </BlurbIconContainer>
        <BlurbText>
          4.9 Trustpilot Rating
        </BlurbText>
      </Blurb>
    </BlurbSection>
  </div>
);

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 25px;
`;

const BlurbIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BlurbSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
const BlurbText = styled.h5`
  ${(props) => responsiveStyleFromTheme(props.theme.Headers.h5)};
  margin-left: 20px;
`;

const Blurb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
