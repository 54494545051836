import React from 'react';
import { CurrencyInput, Label, OptionalElement, SlideToggle, Select } from '@lower/core-components';
import { ValidationError } from '@lightspeed/components/ui/molecules/validation-error/validation-error';
import { FormRow } from '@lightspeed/components/ui/templates/form/form';
import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';

interface PropertyDetailsRefinanceFormProps {
  setPropertyAddressToCurrentAddress: (mortgageApplication: MortgageApplicationStore, updateMortgageApplication: (key: keyof MortgageApplicationStore, value: string) => void) => void;
  propertyAddressIsSameAsBorrowerAddress: boolean;
  errors: Map<string, string[]> | null;
  updateMortgageApplication: (key: keyof MortgageApplicationStore, value: string) => void;
  mortgageApplication: MortgageApplicationStore;
  fireAnalyticsEvent: (eventName: string, eventMetaData?: object) => void;
}

export function PropertyDetailsRefinanceForm({
  setPropertyAddressToCurrentAddress,
  propertyAddressIsSameAsBorrowerAddress,
  errors,
  fireAnalyticsEvent,
  updateMortgageApplication,
  mortgageApplication,
}: PropertyDetailsRefinanceFormProps) {
  return (
    <>
      <FormRow>
        <Label flex={['0 0 100%', 1]}>
          Home Value
          <CurrencyInput
            value={mortgageApplication.propertyHomeValue}
            onChange={(v: string | undefined) => {
              updateMortgageApplication('propertyHomeValue', v || '');
            }}
            placeholder={'$300,000'}
            allowDecimals
          />
          <ValidationError
            path={'propertyHomeValue'}
            errors={errors}
          />
        </Label>
        <Label flex={['0 0 100%', 1]}>
          Mortgage Balance*
          <CurrencyInput
            value={mortgageApplication.propertyMortgageBalance}
            onChange={(v: string | undefined) => {
              updateMortgageApplication('propertyMortgageBalance', v || '');
            }}
            placeholder={'$200,000'}
            allowDecimals
          />
          <ValidationError
            path={'propertyMortgageBalance'}
            errors={errors}
          />
        </Label>
        <Label flex={['0 0 100%', 1]}>
          Cash-out Requested*
          <CurrencyInput
            value={mortgageApplication.propertyCashOut}
            onChange={(v: string | undefined) => {
              updateMortgageApplication('propertyCashOut', v || '');
            }}
            placeholder={'$0'}
            allowDecimals
          />
          <ValidationError
            path={'propertyCashOut'}
            errors={errors}
          />
        </Label>
      </FormRow>
      <FormRow>
        <Label flex={['0 0 100%', 1]}>
          Property Type*
          <Select
            options={[
              {
                label: 'Select One',
                value: '',
              },
              {
                label: 'Single Family',
                value: 'Single Family',
              }, {
                label: 'Condo',
                value: 'Condo',
              }, {
                label: 'Duplex',
                value: 'Duplex',
              }, {
                label: 'Triplex',
                value: 'Triplex',
              }, {
                label: 'Fourplex',
                value: 'Fourplex',
              },
            ]}
            value={mortgageApplication.propertyType}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('propertyType', e.target.value)}
          />
          <ValidationError
            path={'propertyType'}
            errors={errors}
          />
        </Label>
        <Label flex={1}>
          Residence Type*
          <Select
            options={[
              {
                label: 'Select One',
                value: '',
              },
              {
                label: 'Primary Residence',
                value: 'Primary Residence',
              }, {
                label: 'Secondary Residence',
                value: 'Secondary Residence',
              }, {
                label: 'Investment',
                value: 'Investment',
              }]}
            value={mortgageApplication.propertyResidenceType}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('propertyResidenceType', e.target.value)}
          />
          <ValidationError
            path={'propertyResidenceType'}
            errors={errors}
          />
        </Label>
      </FormRow>
      <FormRow>
        <Label
          alignItems={'center'}
          flex={['0 0 100%', 1]}
          flexDirection={'row'}
          fontSize={'14px'}
          justifyContent={['space-between', 'flex-start']}
          style={{
            gap: '20px',
          }}
        >
          My property address is the same as my current address
          <SlideToggle
            style={{
              flexShrink: '0',
            }}
            data-testid={'checkbox'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              fireAnalyticsEvent(`same_address_toggle_${e.target.checked}`);
              if (!e.target.checked) {
                updateMortgageApplication('propertyAutocompleteValue', '');
                updateMortgageApplication('propertyStreetAddress', '');
                updateMortgageApplication('propertyCity', '');
                updateMortgageApplication('propertyState', '');
                updateMortgageApplication('propertyZipCode', '');
                updateMortgageApplication('propertyCounty', '');
              } else {
                setPropertyAddressToCurrentAddress(mortgageApplication, updateMortgageApplication);
              }
            }}
            checked={propertyAddressIsSameAsBorrowerAddress}
          />
        </Label>
        <OptionalElement show={propertyAddressIsSameAsBorrowerAddress}>
          <ValidationError
            path={'propertyState'}
            errors={errors}
          />
        </OptionalElement>
      </FormRow>
    </>
  );
}
