import { Loader } from '@googlemaps/js-api-loader';
import { ENV } from '@lightspeed/environment';

function initGoogleMaps() {
  return new Loader({
    apiKey: ENV.GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
    version: 'weekly',
  }).load();
}

const map = document.createElement('div');

export async function getAddressByPlaceId(placeId: string, callback: Parameters<typeof google.maps.places.PlacesService.prototype.getDetails>[1]) {
  const google = await initGoogleMaps();

  const request = {
    fields: ['address_components', 'geometry'],
    placeId,
  };

  const service = new google.maps.places.PlacesService(map);
  service.getDetails(request, callback);
}

export async function getAutocompletePredictions(searchText: string, callback: Parameters<typeof google.maps.places.AutocompleteService.prototype.getPlacePredictions>[1]) {
  const googlePromise = initGoogleMaps();
  if (ENV.ENVIRONMENT === 'test') {
    // @ts-expect-error we passed null based on the library test: https://github.com/googlemaps/js-api-loader/blob/ff9e73a2aba1a7a319fc61adbf6b72641a368bf9/src/index.test.ts#L102
    window.__googleMapsCallback(null);
  }

  const request = {
    componentRestrictions: {
      country: 'us',
    },
    input: searchText,
    types: ['geocode'],
  };

  const google = await googlePromise;
  const placesService = new google.maps.places.AutocompleteService();

  await placesService.getPlacePredictions(request, callback);
}
