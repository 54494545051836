import { useAnalytics } from '@lower/analytics';
import { Quote } from '@lower-web-api/generated';
import { appInsights } from '@lightspeed/utils/app-insights';
import { GetQuotingApplicationQuoteResponse, getQuotingApplicationQuotes } from '@lightspeed/api/services/new-backend/lending/getQuotingApplicationQuotes';

const poll = async <T>(
  fn: () => Promise<T>,
  fnCondition: (result: T) => boolean,
  ms: number,
  timeout: number,
  onTimeout: () => void = () => {
    // do nothing
  },
) => {
  let result = await fn();
  let totalTime = 0;
  while (totalTime <= timeout && !fnCondition(result)) {
    await wait(ms);
    totalTime += ms;
    result = await fn();
  }
  if (totalTime > timeout) {
    onTimeout();
  }
  return result;
};

const wait = async (ms = 1000) =>  new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export const pollForQuotes = async (quotingApplicationSubmissionId: string, fireAnalyticsEvent: ReturnType<typeof useAnalytics>) => {
  const getQuotes = (): Promise<GetQuotingApplicationQuoteResponse> => getQuotingApplicationQuotes(quotingApplicationSubmissionId);
  const checkForPollingComplete = (pollResponse: GetQuotingApplicationQuoteResponse) => pollResponse.status === 200;
  try {
    const result = await poll(
      getQuotes,
      checkForPollingComplete,
      2000,
      60000,
      () => fireAnalyticsEvent('polling_for_quotes_timeout'),
    );
    if (result.status === 200) {
      const quotingResponse = await result.json();

      if (quotingResponse.quotes && quotingResponse.quotes.length > 0) {
        const filteredQuotes = quotingResponse.quotes.filter((q: Quote) => q.points && q.points >= 0 && q.points <= 2); // HACK(quinton): 2 is a magic number because the UI current only supports selecting loan products up to 2.
        quotingResponse.quotes = filteredQuotes;
      }

      const quotes = quotingResponse.quotes || [];

      return quotes.map((quote) => ({
        apr: quote.apr || 0,
        discountCost: quote.pointsCost || 0,
        discountPoints: quote.points || 0,
        estimatedClosingCosts: quote.estimatedClosingCosts || 0,
        estimatedMonthlyPayment: quote.estimatedMonthlyPayment || 0,
        estimatedPrincipalAndInterest: quote.principalAndInterest || 0,
        estimatedPrivateMortgageInsurance: quote.estimatedMonthlyMortgageInsurance || 0,
        id: quote.id || '',
        loanTerm: (quote.term || '')[0].toUpperCase() + (quote.term || '').substring(1),
        rate: quote.interestRate || 0,
      }));
    }
    return [];
  } catch {
    const error =  new Error('failed during polling for quotes');
    appInsights.trackException({
      exception: error,
    });
    throw error;
  }
};
