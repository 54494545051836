import { IndividualPersonalizedLoan } from '@lightspeed/api/services/personalizedLoanProducts/bestavailable';

type Term = 'fifteen'|'twenty'|'thirty';

export const TERM_MAP: Record<string, 'Fifteen'|'Twenty'|'Thirty'> = {
  fifteen: 'Fifteen',
  thirty: 'Thirty',
  twenty: 'Twenty',
};

export function getClosestLoanProduct(
  loanProducts: IndividualPersonalizedLoan[],
  term: Term,
  targetDiscountPointValue: number,
): IndividualPersonalizedLoan {
  // filter out loan products that don't have the term we want
  const filteredProductsByTerm = loanProducts
    .filter((lp: IndividualPersonalizedLoan) => lp.loanTerm === TERM_MAP[term]);

  const [firstProduct, ...restOfProducts] = filteredProductsByTerm;

  // determine which loan product is closest to our target
  let currentClosestProduct = firstProduct;
  let deltaOfCurrentClosestProduct = Math.abs(targetDiscountPointValue - firstProduct.discountPoints);

  restOfProducts.forEach((lp: IndividualPersonalizedLoan) => {
    const delta = Math.abs(targetDiscountPointValue - lp.discountPoints);

    if (delta === deltaOfCurrentClosestProduct && lp.discountPoints > currentClosestProduct.discountPoints) {
      currentClosestProduct = lp;
    } else if (delta < deltaOfCurrentClosestProduct) {
      deltaOfCurrentClosestProduct = delta;
      currentClosestProduct = lp;
    }
  });

  return currentClosestProduct;
}
