import { ENV } from '@lightspeed/environment';
import { fetch, TypedResponse } from '@lightspeed/utils/fetch';
import type { CreateApplicationLeadPayload } from './createApplicationLead';

interface UpdateApplicationLeadResponse {
  errors: string[] | null;
  responseStatus: string;
  status: string;
}

export default function updateApplicationLead(leadId: string, payload: CreateApplicationLeadPayload) {
  return fetch<TypedResponse<UpdateApplicationLeadResponse>>(`${ENV.API_HOST}/api/v1/Lead/ApplicationLead/${leadId}`, {
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  });
}
