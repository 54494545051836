import { ContractStatus, EmploymentType, LoanPurpose, PropertyType, PropertyUsage, QuotingApplication } from '@lower-web-api/generated';
import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { CONTRACT_STATUSES } from '@lightspeed/models/contract-statuses';
import { STATE_NAME_TO_ABBREVIATION } from '@lightspeed/models/us-states';
import { EmploymentStatus } from '@lightspeed/types/income';
import { ReferralCookieData } from '@lightspeed/utils/get-referral-cookie-data';

export const EMPLOYMENT_STATUS_MAP: Record<string, EmploymentType> = {
  [EmploymentStatus.RETIRED]: EmploymentType.Retired,
  [EmploymentStatus.SELF_EMPLOYED]: EmploymentType.SelfEmployed,
  [EmploymentStatus.W2]: EmploymentType.W2,
};

export const CONTRACT_STATUS_MAP: Record<string, ContractStatus> = {
  [CONTRACT_STATUSES.inContract]: ContractStatus.InContract,
  [CONTRACT_STATUSES.homeShopping]: ContractStatus.StillLooking,
  [CONTRACT_STATUSES.justStarting]: ContractStatus.StillLooking,
  [CONTRACT_STATUSES.placedOffer]: ContractStatus.PlacedOffer,
};

export const PROPERTY_RESIDENCE_TYPE_MAP: Record<string, PropertyUsage> = {
  Investment: PropertyUsage.Investment,
  'Primary Residence': PropertyUsage.PrimaryResidence,
  'Secondary Residence': PropertyUsage.SecondHome,
};

export const PROPERTY_TYPE_MAP: Record<string, PropertyType> = {
  Condo: PropertyType.Condo,
  Duplex: PropertyType.Duplex,
  Fourplex: PropertyType.Fourplex,
  'Single Family': PropertyType.SingleFamily,
  Triplex: PropertyType.Triplex,
};

export const LOAN_PURPOSE_MAP: Record<string, LoanPurpose> = {
  Heloc: LoanPurpose.Heloc,
  Purchase: LoanPurpose.Purchase,
  Refinance: LoanPurpose.Refinance,
};

const buildAttribution = (application: MortgageApplicationStore, referralCookieData: ReferralCookieData) => {
  const attribution = {
    friendsAndFamilyReferralProgramEmail: referralCookieData.friends_and_family_referral_program_email,
    gclid: referralCookieData.gclid,
    isFromRateQuery: application.fromRateQuery === 'true',
    lastReferrer: referralCookieData.referrer,
    loanOfficerToken: referralCookieData.loan_officer_token,
    utmCampaign: referralCookieData.utm_campaign,
    utmContent: referralCookieData.utm_content,
    utmMedium: referralCookieData.utm_medium,
    utmSource: referralCookieData.utm_source,
    utmTerm: referralCookieData.utm_term,
  };

  return attribution;
};

const buildLoanIntent = (application: MortgageApplicationStore) => {
  const loanIntent =  {
    cashoutAmount: application.propertyCashOut ? parseFloat(application.propertyCashOut) : undefined,
    contractStatus: application.borrowerContractStatus ? CONTRACT_STATUS_MAP[application.borrowerContractStatus] : undefined,
    downPayment: application.propertyDownPayment ? parseFloat(application.propertyDownPayment) : undefined,
    isDownPaymentPartiallyGifted: application.propertyDownPaymentPartiallyGift ? application.propertyDownPaymentPartiallyGift === 'true' : undefined,
    isFirstTimeHomeBuyer: application.borrowerFirstTimeHomeBuyer ? application.borrowerFirstTimeHomeBuyer === 'true' : undefined,
    mortgageBalance: application.propertyMortgageBalance ? parseFloat(application.propertyMortgageBalance) : undefined,
    propertyValue: application.propertyHomeValue ? parseFloat(application.propertyHomeValue) : undefined,
    purchasePrice: application.propertyPurchasePrice ? parseFloat(application.propertyPurchasePrice) : undefined,
    purpose: application.applicationType ? LOAN_PURPOSE_MAP[application.applicationType] : undefined,
  };

  if (Object.keys(loanIntent).length > 0) {
    return loanIntent;
  }

  return undefined;
};

const buildProperty = (application: MortgageApplicationStore) => {
  const property = {
    addressCity: application.propertyState ? application.propertyCity : undefined,
    addressCounty: application.propertyState ? application.propertyCounty : undefined,
    addressLine1: application.propertyState ? application.propertyStreetAddress : undefined,
    addressLine2: application.propertyState ? '' : undefined, // we don't store line 2 but the backend needs something for it
    addressState: STATE_NAME_TO_ABBREVIATION.get(application.propertyState) || undefined,
    addressZipCode:
      application.propertyState
        ? application.propertyZipCode
          ?  application.propertyZipCode
          : '12345'
        : undefined, // HACK(quinton): remove this internal second ternary once the backend can handle an empty string zip
    occupancy: application.propertyResidenceType ? PROPERTY_RESIDENCE_TYPE_MAP[application.propertyResidenceType] : undefined,
    type: PROPERTY_TYPE_MAP[application.propertyType],
  };

  if (Object.keys(property).length > 0) {
    return property;
  }

  return undefined;
};

const buildBorrower = (application: MortgageApplicationStore) => {
  const borrower = {
    addressCity: application.borrowerCity || undefined,
    addressCounty: application.borrowerCounty || undefined,
    addressLine1: application.borrowerStreetAddress || undefined,
    addressLine2: application.borrowerStreetAddress ? '' : undefined, // we don't store line 2 but the backend needs something for it
    addressState: STATE_NAME_TO_ABBREVIATION.get(application.borrowerState) || undefined,
    addressZipCode: application.borrowerZipCode || undefined,
    creditPullAuthorizedAt: application.borrowerCreditAuthDateTime ? new Date(application.borrowerCreditAuthDateTime) : undefined,
    dateOfBirth: application.borrowerDateOfBirth ? new Date(application.borrowerDateOfBirth) : undefined,
    emailAddress: application.borrowerEmail || undefined,
    employer: application.borrowerEmployer || undefined,
    employmentType: EMPLOYMENT_STATUS_MAP[application.borrowerEmploymentStatus],
    firstName: application.borrowerFirstName || undefined,
    jobTitle: application.borrowerTitle || undefined,
    lastName: application.borrowerLastName || undefined,
    liquidAssets: parseFloat(application.borrowerAmountInSavings) || 0, // TODO(quinton): current backend requires liquid assets but this should be fixed and undefined should be allowed
    otherIncomePerYear: application.borrowerOtherIncomePerYear !== undefined ? parseFloat(application.borrowerOtherIncomePerYear) : undefined,
    phoneNumber: application.borrowerPhoneNumber || undefined,
    ssn: application.borrowerSocialSecurityNumber || undefined,
    yearlyGrossSalary: parseFloat(application.borrowerYearlySalary) || undefined,
    yearsAtAddress: parseInt(application.borrowerYearsAtAddress, 10) || undefined,
    yearsEmployed: parseInt(application.borrowerYearsAtCompany, 10) || undefined,
  };

  if (Object.keys(borrower).length > 0) {
    return borrower;
  }

  return undefined;
};

const buildCoBorrower = (application: MortgageApplicationStore) => {
  const coBorrower = {
    addressCity: application.coBorrowerCity || undefined,
    addressCounty: application.coBorrowerCounty || undefined,
    addressLine1: application.coBorrowerStreetAddress || undefined,
    addressLine2: application.coBorrowerStreetAddress ? '' : undefined, // we don't store line 2 but the backend needs something for it
    addressState: STATE_NAME_TO_ABBREVIATION.get(application.coBorrowerState) || undefined,
    addressZipCode: application.coBorrowerZipCode || undefined,
    creditPullAuthorizedAt: application.coBorrowerCreditAuthDateTime ? new Date(application.coBorrowerCreditAuthDateTime) : undefined,
    dateOfBirth: application.coBorrowerDateOfBirth ? new Date(application.coBorrowerDateOfBirth) : undefined,
    emailAddress: application.coBorrowerEmail || undefined,
    employer: application.coBorrowerEmployer || undefined,
    employmentType: application.coBorrowerEmploymentStatus ? EMPLOYMENT_STATUS_MAP[application.coBorrowerEmploymentStatus] : undefined,
    firstName: application.coBorrowerFirstName || undefined,
    jobTitle: application.coBorrowerTitle || undefined,
    lastName: application.coBorrowerLastName || undefined,
    liquidAssets: application.coBorrowerFirstName ?
      application.coBorrowerAmountInSavings ? parseFloat(application.coBorrowerAmountInSavings) : 0
      : undefined,
    otherIncomePerYear: application.coBorrowerOtherIncomePerYear ? parseFloat(application.coBorrowerOtherIncomePerYear) : undefined,
    phoneNumber: application.coBorrowerPhoneNumber || undefined,
    ssn: application.coBorrowerSocialSecurityNumber || undefined,
    yearlyGrossSalary: application.coBorrowerYearlySalary ? parseFloat(application.coBorrowerYearlySalary) : undefined,
    yearsAtAddress: undefined,
    yearsEmployed: application.coBorrowerYearsAtCompany ? parseInt(application.coBorrowerYearsAtCompany, 10) : undefined,
  };

  if (Object.values(coBorrower).every((val) => val === undefined)) {
    return null;
  }

  if (Object.keys(coBorrower).length > 0) {
    return coBorrower;
  }

  return undefined;
};

export const transformMortgageApplicationToQuotingApplication = (application: MortgageApplicationStore, referralCookieData: ReferralCookieData): QuotingApplication => {
  const builder: QuotingApplication = {
    attribution: buildAttribution(application, referralCookieData),
    borrower: buildBorrower(application),
    coBorrower: buildCoBorrower(application),
    loanIntent: buildLoanIntent(application),
    property: buildProperty(application),
  };

  return builder;
};
