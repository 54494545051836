import styled from 'styled-components';
import { compose, layout, LayoutProps, shadow, ShadowProps, space, SpaceProps } from 'styled-system';
import cx from 'classnames';
import { ThemedProps } from '../../styles/utils/theme-utils';

export type BoxProps = {
  active?: boolean;
  disabled?: boolean;
  className?: string;
} & LayoutProps & SpaceProps & ShadowProps;

export const Box = styled.div.attrs<BoxProps>(
  (props) => ({
    ...props,
    className: cx('Box', props.className, {
      'is-active': props.active,
      'is-disabled': props.disabled,
    }),
  }))<BoxProps>`
  position: relative;
  background-color: #fff;
  border: 1px solid #E5E6EE;
  border-radius: 12px;
  transition:
    border-color ${ (props: ThemedProps) => props.theme.transitions.subtle },
    transform 400ms ${ (props: ThemedProps) => props.theme.easings.easeOutCubic },
    box-shadow 100ms ease-in-out;

  &:active,
  &:hover:active {
    transform: scale(0.95) translateY(-5px);
  }

  &:hover {
    cursor: pointer;
    transform: translateY(-5px);
  }

  &.is-disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }

  &.is-active {
    border-radius: 12px;
    box-shadow: 0 24px 48px rgba(23, 23, 47, 0.06);
    transition: opacity ${ (props: ThemedProps) => props.theme.transitions.prop },
    transform 400ms ${ (props: ThemedProps) => props.theme.easings.easeOutCubic },
    box-shadow 100ms ease-in-out;
    border: 1px solid ${ (props: ThemedProps) => props.theme.colors.brightBlue };
  }

  &.is-active:after {
    opacity: 1;
  }


  ${ compose(layout, space, shadow) };
`;

