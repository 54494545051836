import { RQWRates } from '@lightspeed/contexts/rqw-rates-context/rqw-rates-context';
import { RQWParams } from './rqw-params-transformer';

export function getRQWRates(params: Partial<RQWParams>): RQWRates|null {
  if (
    params.rateFor15YearTerm !== undefined &&
    params.rateFor20YearTerm !== undefined &&
    params.rateFor30YearTerm !== undefined &&
    params.aprFor30YearTerm !== undefined &&
    params.aprFor20YearTerm !== undefined &&
    params.aprFor15YearTerm !== undefined &&
    params.discountPointsFor30YearTerm !== undefined &&
    params.discountPointsFor20YearTerm !== undefined &&
    params.discountPointsFor15YearTerm !== undefined &&
    params.monthlyPaymentFor30YearTerm !== undefined &&
    params.monthlyPaymentFor20YearTerm !== undefined &&
    params.monthlyPaymentFor15YearTerm !== undefined &&
    params.loanAmount !== undefined &&
    params.ltv !== undefined) {
    return {
      aprFor15YearTerm: params.aprFor15YearTerm,
      aprFor20YearTerm: params.aprFor20YearTerm,
      aprFor30YearTerm: params.aprFor30YearTerm,
      discountPointsFor15YearTerm: params.discountPointsFor15YearTerm,
      discountPointsFor20YearTerm: params.discountPointsFor20YearTerm,
      discountPointsFor30YearTerm: params.discountPointsFor30YearTerm,
      loanAmount: params.loanAmount,
      ltv: params.ltv,
      monthlyPaymentFor15YearTerm: params.monthlyPaymentFor15YearTerm,
      monthlyPaymentFor20YearTerm: params.monthlyPaymentFor20YearTerm,
      monthlyPaymentFor30YearTerm: params.monthlyPaymentFor30YearTerm,
      rateFor15YearTerm: params.rateFor15YearTerm,
      rateFor20YearTerm: params.rateFor20YearTerm,
      rateFor30YearTerm: params.rateFor30YearTerm,
      reasonForRefinance: params.reasonForRefinance || '',
    };
  }

  return null;
}
