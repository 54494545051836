import styled from 'styled-components';
import { ChangeEventHandler, FocusEventHandler, FunctionComponent } from 'react';
import cx from 'classnames';
import { ChevronDown } from '@lower/icons';
import { TypographyTokens } from '../../styles/primary/tokens/typography';
import { ThemedProps } from '../../styles/utils/theme-utils';

export interface SelectProps {
  label?: string;
  className?: string;
  disabled?: boolean;
  value: string;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  placeholder?: string;
  options?: Array<SelectOption>;
  hasError?: boolean;
  onFocus?: FocusEventHandler<HTMLSelectElement>;
  onBlur?: () => void;
  name?: string,
}

export interface SelectOption {
  key?: string;
  label: string;
  value: string;
  disabled?: boolean;
  hidden?: boolean;
}

export const Select = ({
  label,
  className,
  value,
  onChange,
  placeholder = 'Select an option',
  options = [],
  disabled = false,
  hasError = false,
  onFocus,
  onBlur = () => {
    // do nothing
  },
  name,
}: SelectProps) => (
  <DropDown
    aria-label={label}
    name={name}
    placeholder={placeholder}
    value={value}
    disabled={disabled}
    onChange={onChange}
    hasError={hasError}
    onFocus={onFocus}
    onBlur={onBlur}
  >
    {
      options && options.map(
        ({
          label: optionLabel, hidden = false, value: optionVal, disabled: optionDisabled,
        }) => (
          <option
            key={optionLabel}
            value={optionVal}
            hidden={hidden}
            disabled={optionDisabled}
          >
            {optionLabel}
          </option>
        ),
      )
    }
  </DropDown>

);

type DropDownProps = JSX.IntrinsicElements['select'] & {
  hasError?: boolean;
};

export const DropDown: FunctionComponent<DropDownProps> = styled.select.attrs<SelectProps>((
  {
    className,
    disabled,
    hasError,
    ...props
  },
) => ({
  className: cx(className, {
    'has-error': hasError,
    'is-disabled': disabled,
  }),
  disabled,
  ...props,
}))`
  appearance: none;
  cursor: pointer;
  font-family: ${ TypographyTokens.Gotham.book };
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.04em;
  height: 50px;
  width: 100%;
  border: 1px solid #E5E6EE;
  border-radius: 12px;
  padding: 15px 35px 15px 20px;
  background: url(${ChevronDown}) 92% center no-repeat ${(props: ThemedProps) => props.theme.colors.white[0]};

  ${(props) => `color: ${props.value === '' ? '#C4C6D1' : '#1D1D23'};`}

  &.has-error {
    border: 1px solid ${(props: ThemedProps) => props.theme.colors.error};
  }
  &:focus-visible {
    outline: 1px solid ${(props: ThemedProps) => props.theme.colors.blue[0]};
  }

  option {
    all: unset;
    font-family: ${ TypographyTokens.Gotham.book };
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    display: block;
    cursor: pointer;
    box-sizing: border-box;
    padding: 5px 20px;
    width: 100%;
    color: #1D1D23;
    background-color: ${(props: ThemedProps) => props.theme.colors.white[0]};

    &:focus-visible {
      outline: 1px solid ${(props: ThemedProps) => props.theme.colors.blue[0]};
    }
    &:hover {
      background-color: ${(props: ThemedProps) => props.theme.colors.gray[0]};
    }
  }
`;
