import { useEffect, useCallback } from 'react';
import { Analytics } from './analytics';

export function useAnalytics(pageName: string) {
  useEffect(() => {
    Analytics.pageView(
      pageName,
      window.location.href,
      window.location.pathname,
    );
  }, [pageName]);

  // return a function that prefixes the events triggered with <pageName_
  return useCallback((eventName: string, eventMetaData: object = {}) => {
    Analytics.event(
      `${pageName}_${eventName}`,
      eventMetaData,
    );
  }, [pageName]);
}
