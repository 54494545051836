import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useAnalytics } from '@lower/analytics';
import { Paragraph, Heading, IconCircle, OptionalElement, ButtonGroup, MobileTray, useModal } from '@lower/core-components';
import { useNavigate } from 'react-router-dom';
import { ComponentProp } from '@lower/typescript-helpers';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { BasePageTemplate  } from '@lightspeed/components/ui/templates';
import Card from '@lightspeed/assets/icons/credit.svg';
import Bolt from '@lightspeed/assets/icons/bolt.svg';
import Star from '@lightspeed/assets/icons/star.svg';
import Shield from '@lightspeed/assets/icons/shield.svg';
import BoltCircle from '@lightspeed/assets/icons/bolt-circle.png';
import { responsiveStyleFromTheme } from '@lightspeed/styles/utils/theme-utils';
import { ROUTES } from '@lightspeed/routing/routing-machine';
import { theme } from '@lightspeed/styles/primary/theme';

const LIGHTNING_BOLT_CLICK_EVENT_NAME = 'lightningBoltClicked';

const BUTTON_GROUP_CONFIG: { buttonLabel: string, link: ROUTES; }[] = [
  {
    buttonLabel: 'Buy',
    link: ROUTES.PURCHASE,
  },
  {
    buttonLabel: 'Refinance',
    link: ROUTES.REFINANCE,
  },
  {
    buttonLabel: 'HELOC',
    link: ROUTES.HELOC,
  },
];

const MOBILE_TRAY_CONFIG: {
  headerText: string,
  links: { linkLabel: string, link: ROUTES; }[],
} = {
  headerText: 'I want to...',
  links:
  [
    {
      link: ROUTES.PURCHASE,
      linkLabel: 'Buy a home',
    },
    {
      link: ROUTES.REFINANCE,
      linkLabel: 'Refinance a home',
    },
    {
      link: ROUTES.HELOC,
      linkLabel: 'Access my home\'s equity',
    },
  ],
};

export function Landing() {
  const fireAnalyticsEvent = useAnalytics('v2/landing');

  const [shouldShow, show, hide] = useModal();
  const navigate = useNavigate();

  const onLinkClick: ComponentProp<typeof MobileTray, 'onLinkClick'> = (link) => {
    navigate(link.link);
  };

  const onButtonClick: ComponentProp<typeof ButtonGroup, 'onClick'> = (button) => {
    navigate(button.link);
  };

  const lightningBoltButtonHandler = () => {
    show();
    fireAnalyticsEvent(LIGHTNING_BOLT_CLICK_EVENT_NAME);
  };
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints[0]})`,
  });

  return (
    <>
      <OptionalElement show={!isDesktop && shouldShow}>
        <MobileTray
          dismiss={hide}
          mobileTrayConfig={MOBILE_TRAY_CONFIG}
          onLinkClick={onLinkClick}
        />
      </OptionalElement>
      <Header />
      <ExtendedTemplate>
        <LightningBoltButton
          data-testid={'lightning-bolt-button'}
          onClick={lightningBoltButtonHandler}
        >
          <Heading
            large={!isDesktop}
            element={'h1'}
            style={{
              textAlign: 'center',
            }}
            marginTop={isDesktop ? '410px' : '300px'}
          >
            Lightspeed
          </Heading>
        </LightningBoltButton>
        <Paragraph
          variant={'smallLight'}
          textAlign={'center'}
          marginTop={'14px'}
          fontSize={'14px'}
          fontWeight={'700'}
        >
          Fastest mortgage app ever. Tap. Details. Done.<br />Submit your application in 3 mins or less.
        </Paragraph>
        <ButtonGroup
          onClick={onButtonClick}
          buttons={BUTTON_GROUP_CONFIG}
        />
        <OptionalElement show={isDesktop && shouldShow}>
          <HelperTextWrapper>
            <HelperText>👆 Select one to get started.</HelperText>
          </HelperTextWrapper>
        </OptionalElement>
        <Blurbs>
          <Blurb>
            <BlurbIconContainer>
              <IconCircle
                background={'rgba(255, 184, 47, 0.1);'}
                icon={Bolt}
              />
            </BlurbIconContainer>
            <Paragraph variant={'smallLight'}>
              Apply. Get approved. Close quickly. Get that house. Boom—fully streamlined.
            </Paragraph>
          </Blurb>
          <Blurb>
            <BlurbIconContainer>
              <IconCircle
                background={'rgba(62, 146, 247, 0.1)'}
                icon={Card}
              />
            </BlurbIconContainer>
            <Paragraph variant={'smallLight'}>
              Your credit is important. We won't do a hard credit pull until absolutely necessary.
            </Paragraph>
          </Blurb>
          <Blurb>
            <BlurbIconContainer>
              <IconCircle
                background={'rgba(0, 182, 122, 0.1)'}
                icon={Star}
              />
            </BlurbIconContainer>
            <Paragraph variant={'smallLight'}>
              Trustpilot Top Lender. 1,300+ reviews. 4.9 rating. And one incredible experience.
            </Paragraph>
          </Blurb>
          <Blurb>
            <BlurbIconContainer>
              <IconCircle
                background={'rgba(98, 54, 255, 0.1)'}
                icon={Shield}
              />
            </BlurbIconContainer>
            <Paragraph variant={'smallLight'}>
              Your data is super safe. We’ll never sell your info to anyone. Scout's honor.
            </Paragraph>
          </Blurb>
        </Blurbs>
        <Paragraph
          variant={'legal'}
          textAlign={'left'}
          width={'100%'}
        >
          Copyright © 2021 Lower. 8131 Smith's Mill Rd New Albany, OH 43054. NMLS ID #1124061 All rights reserved. Equal Housing Lender. All loans are subject to approval, to include the underwriting of credit and collateral. &nbsp;
          <a
            target={'_blank'}
            href={'https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1124061'}
            rel={'noreferrer'}
          >nmlsconsumeraccess.org
          </a>
        </Paragraph>
        <ParagraphLinkContainer>
          <ParagraphLink href={'https://www.lower.com/legal/license-information'}>License</ParagraphLink>
          &nbsp;
          |
          &nbsp;
          <ParagraphLink href={'https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1124061'}>NMLS</ParagraphLink>
          &nbsp;
          |
          &nbsp;
          <ParagraphLink href={'https://www.lower.com/legal/privacy-policy'}>Privacy</ParagraphLink>
          &nbsp;
          |
          &nbsp;
          <ParagraphLink href={'https://www.lower.com/legal/website-terms-of-use'}>Terms</ParagraphLink>
        </ParagraphLinkContainer>
      </ExtendedTemplate>
    </>
  );
}

const Blurbs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin-bottom: 60px;
  margin-top: 20px;

  ${responsiveStyleFromTheme({
    desktop: {
      flexDirection: 'row',
      marginTop: '117px',
    },
  })}
`;

const BlurbIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Blurb = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  p {
    text-align: left;
  }

  ${responsiveStyleFromTheme({
    desktop: {
      alignItems: 'start',
      flexDirection: 'column',
      gap: '8px',
    },
  })}
`;

const ParagraphLinkContainer = styled.div`
  color: #6A6B76;
  font-size: 10px;
  width: 100%;
  margin-top: 14px;
`;

const ParagraphLink = styled.a`
  color: #6A6B76;
  font-weight: bold;
  text-decoration: none;
`;

const ExtendedTemplate = styled(BasePageTemplate)`
  align-items: center;
  flex-direction: column;
  position: relative;
  background: url(${BoltCircle});
  background-repeat: no-repeat;
  background-size: 550px;
  background-position-x: center;
  transform: translateY(-125px);
  ${responsiveStyleFromTheme({
    desktop: {
      backgroundSize: '750px',
      transform: 'translateY(-180px)',
    },
  })}
`;

const LightningBoltButton = styled.div`
  all: unset;
`;

const HelperText = styled.div`
  font-size: 14px;
  line-height: 30px;
  position: absolute;
  width: 500px;
  transform: translateX(-50%);
  left: 50%;
  text-align: center;
`;

const HelperTextWrapper = styled.div`
  position: relative;
`;
