import { Info } from './info';
import { LowerLogo } from './lower-logo';
import { LowerLogoMobile } from './lower-logo-mobile';
import { ClosedHouse } from './closed-house';
import { PersonalApplyTM } from './personal-apply-tm';

export const Symbols = {
  ClosedHouse,
  Info,
  LowerLogo,
  LowerLogoMobile,
  PersonalApplyTM,
};

export type SymbolAsset = `${keyof typeof Symbols}`;
