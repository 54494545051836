import styled from 'styled-components';
import { TypographyTokens } from '../../styles/primary/tokens/typography';
import { ThemedProps } from '../../styles/utils/theme-utils';

export interface TextInputProps {
  $hasError?: boolean;
}

export const TextInput = styled.input.attrs<TextInputProps, TextInputProps>(
  ({
    type = 'text',
    $hasError = false,
    ...props
  }) => ({
    ...props,
    $hasError,
    type,
  }),
)`
  font-family: ${ TypographyTokens.Gotham.book };
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  height: 16px;
  letter-spacing: -0.04em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 20px;
  width: 100%;

  background: #FFFFFF;
  border: 1px solid #E5E6EE;
  box-sizing: border-box;
  border-radius: 12px;

  transition: border ${ (props: ThemedProps) => props.theme.transitions.prop };

  // firefox specific padding for date input
  @-moz-document url-prefix() {
    &[type='date'] {
      padding: 15px 24px 33px 24px;
    }
  }

  ${({ $hasError, theme }: ThemedProps & TextInputProps) => $hasError && `
    border: 1px solid ${theme.colors.error};
  `}

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1 + */
    color: #C4C6D1;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10 - 11 */
    color: #C4C6D1;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #C4C6D1;
  }

  &:hover {
  }

  &:disabled {
  }
`;
