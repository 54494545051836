import { usePlaidLink } from 'react-plaid-link';
import { useEffect, useState } from 'react';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { createPlaidLinkToken } from '@lightspeed/api/services/new-backend/lending/createPlaidLinkToken';

function usePlaidLinkToken(leadId: string) {
  const [token, setToken] = useState<null|string>(null);

  useEffect(() => {
    (async () => {
      const response = await createPlaidLinkToken(leadId);
      if (response.status === 200) {
        const data = await response.json();

        if (!data.linkToken) {
          throw new Error('Did not receive a plaid token');
        }

        setToken(data.linkToken);
      } else {
        // TODO: implement better error handling
      }
    })();
  }, []);

  return token;
}

export function AssetLink() {
  const [linkingStatus, setLinkingStatus] = useState<string>('Linking');
  const { mortgageApplication } = useMortgageApplication();

  const token = usePlaidLinkToken(mortgageApplication.leadId);

  const { open, ready } = usePlaidLink({
    env: 'sandbox',
    // eslint-disable-next-line camelcase
    onSuccess: (public_token) => {
      // eslint-disable-next-line camelcase
      setLinkingStatus(`Linked: ${public_token}`);
    },
    token,
  });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return (
    <div>{linkingStatus}</div>
  );
}
