import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ApplyPolyfills } from '@lightspeed/utils/polyfills';
import { getRateQueryWidgetParams } from '@lightspeed/transformers/rqw-params-transformer';
import { getRQWRates } from '@lightspeed/transformers/rqw-rates-transformer';
import { AppProvider } from '@lightspeed/components/app/app-provider';
import { ROUTES } from '@lightspeed/routing/routing-machine';
import { ProcessingRates } from '@lightspeed/components/ui/pages/v2/processing/processing-rates';
import { ProcessingLeadSubmission } from '@lightspeed/components/ui/pages/v2/processing/processing-lead-submission';
import { RatesSelect } from '@lightspeed/components/ui/pages/v2/rates-select/rates-select';
import { Confetti } from '@lightspeed/components/ui/pages/v2/confetti/confetti';
import { Heloc } from '@lightspeed/components/ui/pages/v2/get-started/heloc';
import { Purchase } from '@lightspeed/components/ui/pages/v2/get-started/purchase';
import { Landing } from '@lightspeed/components/ui/pages/v2/get-started/landing';
import { Refinance } from '@lightspeed/components/ui/pages/v2/get-started/refinance';
import {
  CurrentAddressNonPurchase,
} from '@lightspeed/components/ui/pages/v2/current-address-non-purchase/current-address-non-purchase';
import {
  CurrentAddressPurchase,
} from '@lightspeed/components/ui/pages/v2/current-address-purchase/current-address-purchase';
import { YourAssets } from '@lightspeed/components/ui/pages/v2/assets/your-assets';
import { VerifyIdentity } from '@lightspeed/components/ui/pages/v2/verify-identity/verify-identity';
import { Income } from '@lightspeed/components/ui/pages/v2/income/income';
import { PropertyNonPurchase } from '@lightspeed/components/ui/pages/v2/property-refinance/property-non-purchase';
import {
  PropertyPurchaseInContract,
} from '@lightspeed/components/ui/pages/v2/property-purchase/property-purchase-in-contract';
import {
  PropertyPurchaseNotInContract,
} from '@lightspeed/components/ui/pages/v2/property-purchase/property-purchase-not-in-contract';
import { URLWatcher } from '@lightspeed/components/app/url-watcher';
import { Error } from '@lightspeed/components/ui/pages/error/error';
import { useLoanOfficerToken } from '@lightspeed/hooks/useLoanOfficerToken/useLoanOfficerToken';
import {
  useFriendsAndFamilyReferralProgramEmail,
} from '@lightspeed/hooks/useFriendsAndFamilyReferralProgramEmail/useFriendsAndFamilyReferralProgramEmail';
import '@lightspeed/utils/data-dog';
import { UnsupportedStates } from '@lightspeed/components/ui/pages/v2/unsupported-states/unsupported-states';
import { NonPreapprovalSuccess } from '@lightspeed/components/ui/pages/v2/non-preapproval-success/non-preapproval-success';
import { AssetLink } from '@lightspeed/components/ui/pages/v2/asset-link/asset-link';

ApplyPolyfills();

const mortgageOverrides = getRateQueryWidgetParams(window.location.href);

const rqwRatesOverrides = getRQWRates(mortgageOverrides);

// NOTE(quinton): the fallback component is necessary as without it we lose query params when replacing
const FallbackComponent = () => {
  const { search } = useLocation();
  return (
    <Navigate
      replace
      to={`${ROUTES.LANDING}${search}`}
    />
  );
};

export function App() {
  useLoanOfficerToken();
  useFriendsAndFamilyReferralProgramEmail();

  return (
    <AppProvider
      mortgageContextOverride={mortgageOverrides}
      rqwRatesContextOverride={rqwRatesOverrides}
    >
      <BrowserRouter>
        <URLWatcher />
        <Routes>
          <Route
            path={ROUTES.ASSET_LINK}
            element={<AssetLink />}
          />
          <Route
            path={ROUTES.LANDING}
            element={<Landing />}
          />
          <Route
            path={ROUTES.HELOC}
            element={<Heloc />}
          />
          <Route
            path={ROUTES.PURCHASE}
            element={<Purchase />}
          />
          <Route
            path={ROUTES.REFINANCE}
            element={<Refinance />}
          />
          <Route
            path={ROUTES.CURRENT_ADDRESS_NON_PURCHASE}
            element={<CurrentAddressNonPurchase />}
          />
          <Route
            path={ROUTES.PROPERTY_NON_PURCHASE}
            element={<PropertyNonPurchase />}
          />
          <Route
            path={ROUTES.CURRENT_ADDRESS_PURCHASE}
            element={<CurrentAddressPurchase />}
          />
          <Route
            path={ROUTES.PROPERTY_PURCHASE_IN_CONTRACT}
            element={<PropertyPurchaseInContract />}
          />
          <Route
            path={ROUTES.PROPERTY_PURCHASE_NOT_IN_CONTRACT}
            element={<PropertyPurchaseNotInContract />}
          />
          <Route
            path={ROUTES.ASSETS}
            element={<YourAssets />}
          />
          <Route
            path={ROUTES.INCOME}
            element={<Income />}
          />
          <Route
            path={ROUTES.IDENTITY}
            element={<VerifyIdentity />}
          />
          <Route
            path={ROUTES.PROCESSING_RATES}
            element={<ProcessingRates />}
          />
          <Route
            path={ROUTES.RATES_SELECT}
            element={<RatesSelect />}
          />
          <Route
            path={ROUTES.PROCESSING_LEAD_SUBMISSION}
            element={<ProcessingLeadSubmission />}
          />
          <Route
            path={ROUTES.CONFETTI}
            element={<Confetti />}
          />
          <Route
            path={ROUTES.ERROR}
            element={<Error />}
          />
          <Route
            path={ROUTES.UNSUPPORTED_MARKET}
            element={<UnsupportedStates />}
          />
          <Route
            path={ROUTES.NON_PREAPPROVAL_SUCCESS}
            element={<NonPreapprovalSuccess />}
          />
          <Route
            path={'*'}
            element={(
              <FallbackComponent />
            )}
          />
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
}
