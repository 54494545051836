import styled from 'styled-components';
import { HTMLAttributes } from 'react';

export function SplitSection(props: HTMLAttributes<HTMLDivElement>) {
  return <Container {...props} />;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 60px;
`;

SplitSection.Content = styled.main`
  display: flex;
  flex: 6;
  flex-direction: column;
  margin: 0 auto;
`;

SplitSection.Sidebar = styled.aside`
  display: flex;
  order: 2;
  flex: 4;
  flex-direction: column;
  margin: 0 auto;

  button {
    max-width: 240px !important;
  }
`;

SplitSection.LegalFooter = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
`;
