import React, { useState } from 'react';
import styled from 'styled-components';
import { useAnalytics } from '@lower/analytics';
import { Heading, Button, Label, OptionalElement, LoadingModal, SlideToggle } from '@lower/core-components';
import { CardSelect } from '@lightspeed/components/ui/organisms';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { TermSelect } from '@lightspeed/components/shared/terms-select/terms-select';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { MortgageApplicationStore, MortgageApplicationStoreKeys } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { useValidation } from '@lightspeed/hooks/useValidation/useValidation';
import { mortgageApplicationSchema } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';
import { CONTRACT_STATUSES } from '@lightspeed/models/contract-statuses';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { TodaysRates } from '@lightspeed/components/ui/pages/v2/get-started/basic-info/todays-rates';
import { Form } from '@lightspeed/components/ui/templates/form/form';
import { RateDisclaimer } from '@lightspeed/components/shared/legal-disclaimers/rate-disclaimer';
import { PurchaseRefinanceSidebarBlurb } from '@lightspeed/components/shared/purchase-refinance-sidebar-blurb/purchase-refinance-sidebar-blurb';
import { useMortgageContextDefault } from '@lightspeed/hooks/useMortgageContextDefaults/useMortgageContextDefaults';
import { useApplicationLead } from '@lightspeed/hooks/useApplicationLead/useApplicationLead';
import { getButtonDisabledState } from '@lightspeed/utils/get-button-disabled-state';
import { AddressForm } from './address-form';

const borrowerKeys: MortgageApplicationStoreKeys[] = [
  'borrowerContractStatus',
  'borrowerFirstTimeHomeBuyer',
  'borrowerStreetAddress',
  'borrowerCity',
  'borrowerZipCode',
  'borrowerState',
  'borrowerYearsAtAddress',
];

const coBorrowerKeys: MortgageApplicationStoreKeys[] = [
  'coBorrowerStreetAddress',
  'coBorrowerCity',
  'coBorrowerZipCode',
  'coBorrowerState',
];

function checkIfBorrowersShareAddress(mortgageApplication: MortgageApplicationStore): boolean {
  return (
    mortgageApplication.borrowerCity === mortgageApplication.coBorrowerCity &&
    mortgageApplication.borrowerState === mortgageApplication.coBorrowerState &&
    mortgageApplication.borrowerZipCode === mortgageApplication.coBorrowerZipCode &&
    mortgageApplication.borrowerStreetAddress === mortgageApplication.coBorrowerStreetAddress &&
    mortgageApplication.borrowerCounty === mortgageApplication.coBorrowerCounty
  );
}

export function CurrentAddressPurchase() {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const hasCoBorrower = !!mortgageApplication.coBorrowerFirstName;
  const [coBorrowerHasSameAddress, setCoBorrowerHasSameAddress] = useState<boolean>(checkIfBorrowersShareAddress(mortgageApplication));
  const goToNextRoute = useNextRoute(mortgageApplication);
  const fireAnalyticsEvent = useAnalytics('v2/current_address_purchase');
  useMortgageContextDefault('borrowerContractStatus');
  useMortgageContextDefault('borrowerFirstTimeHomeBuyer');
  const { upsertLead } = useApplicationLead();
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: hasCoBorrower ? [...borrowerKeys, ...coBorrowerKeys] : borrowerKeys,
    yupValidationContext: {
      hasCoBorrower,
    },
  });

  const disableNextButton = getButtonDisabledState<MortgageApplicationStore>(
    mortgageApplication,
    hasCoBorrower ? coBorrowerHasSameAddress ? borrowerKeys : [...borrowerKeys, ...coBorrowerKeys] : borrowerKeys,
  );

  const onNextClick = () => {
    (async () => {
      if (hasCoBorrower && coBorrowerHasSameAddress) {
        updateMortgageApplication('coBorrowerStreetAddress', mortgageApplication.borrowerStreetAddress);
        updateMortgageApplication('coBorrowerCity', mortgageApplication.borrowerCity);
        updateMortgageApplication('coBorrowerCounty', mortgageApplication.borrowerCounty);
        updateMortgageApplication('coBorrowerZipCode', mortgageApplication.borrowerZipCode);
        updateMortgageApplication('coBorrowerState', mortgageApplication.borrowerState);
      }

      const validationResult = validate(mortgageApplication);

      if (validationResult.success) {
        setShowLoadingModal(true);
        try {
          await upsertLead();
          setShowLoadingModal(false);
        } catch (e) {
          // do nothing
        }
        fireAnalyticsEvent('next_button_success');
        goToNextRoute();
      }
    })();
  };

  const toggleFirstTimeHomeBuyer = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateMortgageApplication('borrowerFirstTimeHomeBuyer', e.target.checked ? 'Yes' : 'No');
    fireAnalyticsEvent('first_time_home_buyer_touched');
  };

  return (
    <>
      <OptionalElement show={showLoadingModal}>
        <LoadingModal />
      </OptionalElement>
      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <Heading
                    element={'h1'}
                  >
                    The basics.
                  </Heading>
                  <Form>
                    <Heading
                      element={'h4'}
                      marginTop={'48px'}
                    >
                      What’s your next step?
                    </Heading>
                    <CardSelect
                      onChange={(v) => {
                        updateMortgageApplication('borrowerContractStatus', v);
                      }}
                      value={mortgageApplication.borrowerContractStatus}
                      options={[
                        {
                          label: 'I’m ready to get pre-approved.',
                          tag: {
                            text: 'Home Shopping',
                            variant: 'purple',
                          },
                          value: CONTRACT_STATUSES.homeShopping,
                        },
                        {
                          label: 'I want to lock in financing soon.',
                          tag: {
                            text: 'Offer Placed',
                            variant: 'teal',
                          },
                          value: CONTRACT_STATUSES.placedOffer,
                        },
                        {
                          label: 'I need financing as soon as possible.',
                          tag: {
                            text: 'In-contract',
                            variant: 'blue',
                          },
                          value: CONTRACT_STATUSES.inContract,
                        },
                      ]}
                    />
                  </Form>
                  <Label
                    flexDirection={'row'}
                    alignItems={'center'}
                  >
                    I am a first-time homebuyer
                    <SlideToggle
                      onChange={toggleFirstTimeHomeBuyer}
                      checked={mortgageApplication.borrowerFirstTimeHomeBuyer === 'Yes'}
                    />
                  </Label>
                  <AddressForm
                    fireAnalyticsEvent={fireAnalyticsEvent}
                    mortgageApplication={mortgageApplication}
                    hasCoBorrower={hasCoBorrower}
                    updateMortgageApplication={updateMortgageApplication}
                    errors={errors}
                    coBorrowerHasSameAddress={coBorrowerHasSameAddress}
                    setCoBorrowerHasSameAddress={setCoBorrowerHasSameAddress}
                  />
                </SplitSection.Content>
                <SplitSection.Sidebar>
                  <OptionalElement show={mortgageApplication.applicationType !== 'Heloc'}>
                    <TodaysRates
                      mortgageApplication={mortgageApplication}
                    />
                  </OptionalElement>
                  <Label marginBottom={'8px'}>
                    Loan Term
                  </Label>
                  <TermSelect
                    value={mortgageApplication.loanTerm}
                    onChange={(v) => {
                      updateMortgageApplication('loanTerm', v);
                    }}
                  />
                  <OptionalElement show={mortgageApplication.applicationType !== 'Heloc'}>
                    <PurchaseRefinanceSidebarBlurb />
                  </OptionalElement>
                  <Button
                    marginY={'32px'}
                    disabled={disableNextButton}
                    onClick={onNextClick}
                  >
                    Next
                  </Button>
                  <RateDisclaimer />
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}
        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <Heading element={'h1'}>
                The basics.
              </Heading>
              <Form>
                <Heading
                  element={'h4'}
                >
                  What’s your next step?
                </Heading>
                <ContractStatusWrapper>
                  <MobileContractStatusSlider>
                    <CardSelect
                      onChange={(v) => {
                        updateMortgageApplication('borrowerContractStatus', v);
                      }}
                      value={mortgageApplication.borrowerContractStatus}
                      options={[
                        {
                          label: 'I’m ready to get pre-approved.',
                          tag: {
                            text: 'Home Shopping',
                            variant: 'purple',
                          },
                          value: CONTRACT_STATUSES.homeShopping,
                        },
                        {
                          label: 'I want to lock in financing soon.',
                          tag: {
                            text: 'Offer Placed',
                            variant: 'green',
                          },
                          value: CONTRACT_STATUSES.placedOffer,
                        },
                        {
                          label: 'I need financing as soon as possible.',
                          tag: {
                            text: 'In-contract',
                            variant: 'blue',
                          },
                          value: CONTRACT_STATUSES.inContract,
                        },
                      ]}
                    />
                  </MobileContractStatusSlider>
                </ContractStatusWrapper>
              </Form>
              <Label
                flexDirection={'row'}
                alignItems={'center'}
              >
                I am a first-time homebuyer
                <SlideToggle
                  onChange={toggleFirstTimeHomeBuyer}
                  checked={mortgageApplication.borrowerFirstTimeHomeBuyer === 'Yes'}
                />
              </Label>

              <AddressForm
                fireAnalyticsEvent={fireAnalyticsEvent}
                mortgageApplication={mortgageApplication}
                hasCoBorrower={hasCoBorrower}
                updateMortgageApplication={updateMortgageApplication}
                errors={errors}
                coBorrowerHasSameAddress={coBorrowerHasSameAddress}
                setCoBorrowerHasSameAddress={setCoBorrowerHasSameAddress}
              />

              <Button
                marginTop={'32px'}
                marginBottom={'32px'}
                disabled={disableNextButton}
                onClick={onNextClick}
              >
                Next
              </Button>
            </BasePageTemplate>
          </>
        )}
      />
    </>
  );
}

const ContractStatusWrapper = styled.div`
  overflow-x: scroll;
  overfloy-y: visible;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  padding: 6px 0;
  margin: -6px 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const MobileContractStatusSlider = styled.div`
  width: 120vw;
  overflow: visible;
`;
