import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Heading, Button, OptionalElement, Paragraph, Label, LoadingModal } from '@lower/core-components';
import { TermSelect } from '@lightspeed/components/shared/terms-select/terms-select';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { MortgageApplicationStore, MortgageApplicationStoreKeys } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { useValidation } from '@lightspeed/hooks/useValidation/useValidation';
import { mortgageApplicationSchema } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-validation';
import { getButtonDisabledState } from '@lightspeed/utils/get-button-disabled-state';
import { PurchaseRefinanceSidebarBlurb } from '@lightspeed/components/shared/purchase-refinance-sidebar-blurb/purchase-refinance-sidebar-blurb';
import { RateDisclaimer } from '@lightspeed/components/shared/legal-disclaimers/rate-disclaimer';
import { HelocSidebarBlurb } from '@lightspeed/components/shared/heloc-sidebar-blurb/heloc-sidebar-blurb';
import { useApplicationLead } from '@lightspeed/hooks/useApplicationLead/useApplicationLead';
import { isUnsupportedMarket } from '@lightspeed/utils/get-is-unsupported-market';
import { BasicInfoForm } from './basic-info-form';
import { TodaysRates } from './todays-rates';

const borrowerKeys: MortgageApplicationStoreKeys[] = [
  'borrowerFirstName',
  'borrowerLastName',
  'borrowerEmail',
  'borrowerPhoneNumber',
  'propertyCity',
  'propertyState',
];

const coBorrowerKeys: MortgageApplicationStoreKeys[] = [
  'coBorrowerFirstName',
  'coBorrowerLastName',
  'coBorrowerEmail',
  'coBorrowerPhoneNumber',
];

const ParagraphLinkContainer = styled.div`
  color: #6A6B76;
  font-size: 10px;
  width: 100%;
  margin-top: 14px;
`;

const ParagraphLink = styled.a`
  color: #6A6B76;
  font-weight: bold;
  text-decoration: none;
`;

function StartYourApplicationDisclaimer() {
  return (
    <Paragraph variant={'legal'}>
      By starting your application, you agree to the&nbsp;
      <a
        target={'_blank'}
        href={'https://www.lower.com/details/personal-apply-terms'}
        rel={'noreferrer'}
      >
        Terms of Use
      </a>,&nbsp;
      <a
        target={'_blank'}
        href={'https://www.lower.com/details/personal-apply-terms#sms'}
        rel={'noreferrer'}
      >
        SMS Texting
      </a>,&nbsp;
      <a
        target={'_blank'}
        href={'https://www.lower.com/details/personal-apply-terms#electronic-docs'}
        rel={'noreferrer'}
      >Electronic Documents
      </a>,&nbsp;
      <a
        target={'_blank'}
        href={'https://www.lower.com/details/personal-apply-terms#realtor'}
        rel={'noreferrer'}
      >Realtor Communication
      </a>. Results from application do not represent a commitment to lend for the terms specified and are subject to credit approval.
    </Paragraph>
  );
}

interface BasicInfoProps {
  fireAnalyticsEvent: (eventName: string, eventMetaData: object) => void
}

export function BasicInfoTemplate({ fireAnalyticsEvent }: BasicInfoProps) {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const [hasCoborrower, setHasCoborrower] = useState(!!mortgageApplication.coBorrowerFirstName);
  const goToNextRoute = useNextRoute(mortgageApplication);
  const [loadingApplicationLeadCreation, setLoadingApplicationLeadCreation] = useState(false);
  const { upsertLead } = useApplicationLead();

  const hasFiredFromRQWEvent = useRef(false);
  useEffect(() => {
    if (mortgageApplication.fromRateQuery === 'true' && !hasFiredFromRQWEvent.current) {
      hasFiredFromRQWEvent.current = true;
      fireAnalyticsEvent('entry_from_rqw', {});
    }
  }, [mortgageApplication, fireAnalyticsEvent]);

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: hasCoborrower ? [...borrowerKeys, ...coBorrowerKeys] : borrowerKeys,
    yupValidationContext: {
      hasCoBorrower: hasCoborrower,
    },
  });

  const disableNextButton = getButtonDisabledState<MortgageApplicationStore>(
    mortgageApplication,
    hasCoborrower ? [...borrowerKeys, ...coBorrowerKeys] : borrowerKeys,
  );

  const onNextClick = () => {
    (async () => {
      if (validate(mortgageApplication).success) {
        if (isUnsupportedMarket(mortgageApplication.propertyState)) {
          fireAnalyticsEvent('bad_state', {
            state: mortgageApplication.propertyState,
          });

          goToNextRoute();
        } else {
          setLoadingApplicationLeadCreation(true);

          try {
            await upsertLead();
            setLoadingApplicationLeadCreation(false);
          } catch (e) {
          // eslint-disable-next-line no-alert
            alert('Something went wrong while submitting your application - please try again');
            setLoadingApplicationLeadCreation(false);
            return;
          }

          fireAnalyticsEvent('next_button_success', {
            type: mortgageApplication.applicationType,
          });

          goToNextRoute();
        }
      }
    })();
  };

  return (
    <>
      <OptionalElement show={loadingApplicationLeadCreation}>
        <LoadingModal />
      </OptionalElement>
      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <Heading element={'h1'}>
                    Let’s get started.
                  </Heading>
                  <BasicInfoForm
                    mortgageApplication={mortgageApplication}
                    hasCoborrower={hasCoborrower}
                    setHasCoborrower={setHasCoborrower}
                    updateMortgageApplication={updateMortgageApplication}
                    errors={errors}
                  />
                  <SplitSection.LegalFooter>
                    <div>
                      <StartYourApplicationDisclaimer />
                      <ParagraphLinkContainer>
                        <ParagraphLink href={'https://www.lower.com/legal/license-information'}>License</ParagraphLink>
                        &nbsp;
                        |
                        &nbsp;
                        <ParagraphLink href={'https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1124061'}>NMLS</ParagraphLink>
                        &nbsp;
                        |
                        &nbsp;
                        <ParagraphLink href={'https://www.lower.com/legal/privacy-policy'}>Privacy</ParagraphLink>
                        &nbsp;
                        |
                        &nbsp;
                        <ParagraphLink href={'https://www.lower.com/legal/website-terms-of-use'}>Terms</ParagraphLink>
                      </ParagraphLinkContainer>
                    </div>
                  </SplitSection.LegalFooter>
                </SplitSection.Content>
                <SplitSection.Sidebar>
                  <OptionalElement show={mortgageApplication.applicationType !== 'Heloc'}>
                    <TodaysRates
                      mortgageApplication={mortgageApplication}
                    />
                    <Label
                      marginBottom={'8px'}
                    >
                      Loan Term
                    </Label>
                    <TermSelect
                      value={mortgageApplication.loanTerm}
                      onChange={(v) => {
                        updateMortgageApplication('loanTerm', v);
                      }}
                    />
                  </OptionalElement>
                  <OptionalElement show={mortgageApplication.applicationType !== 'Heloc'}>
                    <PurchaseRefinanceSidebarBlurb />
                  </OptionalElement>
                  <OptionalElement show={mortgageApplication.applicationType === 'Heloc'}>
                    <HelocSidebarBlurb />
                  </OptionalElement>
                  <Button
                    marginY={'32px'}
                    disabled={disableNextButton}
                    onClick={onNextClick}
                  >
                    Next
                  </Button>
                  <RateDisclaimer />
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}
        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <Heading element={'h1'}>
                Let’s get started.
              </Heading>
              <BasicInfoForm
                mortgageApplication={mortgageApplication}
                hasCoborrower={hasCoborrower}
                setHasCoborrower={setHasCoborrower}
                updateMortgageApplication={updateMortgageApplication}
                errors={errors}
              />
              <Button
                marginTop={'32px'}
                marginBottom={'32px'}
                disabled={disableNextButton}
                onClick={onNextClick}
              >
                Next
              </Button>
              <StartYourApplicationDisclaimer />
            </BasePageTemplate>
          </>
        )}
      />
    </>
  );
}
