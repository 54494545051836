export const LowerLogo = () => (
  <svg
    width={'95'}
    height={'30'}
    viewBox={'0 0 95 30'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      d={'M88.4681 5.8335H89.2381V3.0435H90.3031V2.3335H87.4031V3.0435H88.4681V5.8335ZM91.0453 5.8335H91.8003V3.5635L92.7753 5.0435H92.7953L93.7803 3.5485V5.8335H94.5453V2.3335H93.7153L92.7953 3.8135L91.8753 2.3335H91.0453V5.8335Z'}
      fill={'black'}
    />
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={'M29.7353 18.7712C29.7353 20.9922 29.0913 23.1634 27.8848 25.0101C26.6783 26.8567 24.9634 28.2959 22.9571 29.1456C20.9508 29.9954 18.7432 30.2174 16.6136 29.7837C14.4839 29.35 12.5278 28.2799 10.9927 26.709C9.45764 25.1381 8.41252 23.1368 7.98956 20.9583C7.5666 18.7798 7.7848 16.5219 8.61655 14.4702C9.44831 12.4185 10.8562 10.6652 12.6623 9.43209C14.4684 8.19895 16.5914 7.54136 18.7629 7.54248C21.6714 7.55248 24.458 8.73871 26.5147 10.8423C28.5714 12.946 29.7311 15.7962 29.7409 18.7712'}
      fill={'#001CDB'}
    />
    <path
      d={'M64.4311 6.08643C59.4871 6.08643 55.9668 10.2863 55.9668 15.3432V15.4285C55.9668 20.9158 59.7392 24.6871 64.9335 24.6871C68.0348 24.6871 70.2983 23.4016 71.8907 21.3443L69.1248 18.7713C67.7827 20.0569 66.6111 20.5726 65.0187 20.5726C62.8387 20.5726 61.3297 19.3724 60.8274 17.0572H72.7286C72.7286 16.6287 72.812 16.1092 72.812 15.6864C72.7286 10.7148 70.1296 6.08643 64.4311 6.08643ZM60.744 13.9666C61.1629 11.6515 62.4123 10.1953 64.4311 10.1953C66.4498 10.1953 67.7827 11.7368 68.0348 13.9666H60.744Z'}
      fill={'#1D1D23'}
    />
    <path
      d={'M47.5885 17.315L44.3203 6.34229H40.129L36.9442 17.2297L34.1802 6.42951H29.2344L34.5139 24.4292H38.8721L42.2256 13.4564L45.4938 24.4292H49.8501L55.2149 6.42951H50.3544L47.5885 17.315Z'}
      fill={'#1D1D23'}
    />
    <path
      d={'M4.86058 0H0V24.344H4.86058V0Z'}
      fill={'#1D1D23'}
    />
    <path
      d={'M80.5578 10.0283V6.42576H75.6973V24.2491H80.5578V17.6582C80.5578 13.3712 82.5692 11.4011 85.7484 11.4011H86.0005V6.09205C83.155 6.00293 81.5644 7.54256 80.5578 10.0283Z'}
      fill={'#1D1D23'}
    />
  </svg>
);
