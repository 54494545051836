import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { IndividualPersonalizedLoan } from '@lightspeed/api/services/personalizedLoanProducts/bestavailable';
import { ReferralCookieData } from '@lightspeed/utils/get-referral-cookie-data';
import { CreateApplicationLeadPayload } from '@lightspeed/api/services/leads/createApplicationLead';
import { buildApplication, buildAttribution, buildGuidedExperience } from '@lightspeed/utils/partial-lead-transformer-helpers';

export function transformApplicationToLead(mortgageApplicationContext: MortgageApplicationStore, referralCookieData: ReferralCookieData, selectedLoan?: IndividualPersonalizedLoan, isComplete = false): CreateApplicationLeadPayload {
  return {
    application: buildApplication(mortgageApplicationContext, isComplete),
    attribution: buildAttribution(mortgageApplicationContext, referralCookieData),
    guidedExperience: buildGuidedExperience(mortgageApplicationContext, selectedLoan),
  };
}

