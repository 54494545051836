import bestavailable, { IndividualPersonalizedLoan } from '@lightspeed/api/services/personalizedLoanProducts/bestavailable';
import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { transformApplicationToLoanProductsPayload } from '@lightspeed/transformers/loan-products-transformer';

export const getLoanProducts = async (mortgageApplication: MortgageApplicationStore) => {
  const bestAvailablePayload = transformApplicationToLoanProductsPayload(mortgageApplication, mortgageApplication.applicationType);

  const response = await bestavailable(bestAvailablePayload);
  const result = await response.json();

  if (result.loanProducts && result.loanProducts.length > 0) {
    const filteredLoanProducts = result.loanProducts.filter((lp: IndividualPersonalizedLoan) => lp.discountPoints >= 0 && lp.discountPoints <= 2); // HACK(quinton): 2 is a magic number because the UI current only supports selecting loan products up to 2.
    result.loanProducts = filteredLoanProducts;
  }

  if (result.loanProducts && result.loanProducts.length > 0) {
    return result;
  }

  throw new Error('No loan products.');
};
