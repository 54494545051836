import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useAnalytics } from '@lower/analytics';
import { Button, Label, Separator, Heading, OptionalElement, Symbol, Paragraph, SwipeButton, LoadingModal, ToolTip } from '@lower/core-components';
import { AccordionList, AccordionListProps } from '@lightspeed/components/ui/organisms/accordion-list/accordion-list';
import { CircleButtonSelect } from '@lightspeed/components/ui/organisms';
import { TermSelect } from '@lightspeed/components/shared/terms-select/terms-select';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { Scoreboard } from '@lightspeed/components/ui/molecules/scoreboard/scoreboard';
import { Form, FormRow } from '@lightspeed/components/ui/templates/form/form';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { IndividualPersonalizedLoan } from '@lightspeed/api/services/personalizedLoanProducts/bestavailable';
import { getClosestLoanProduct } from '@lightspeed/utils/get-closest-loan-product';
import { usePersonalizedLoan } from '@lightspeed/contexts/personalized-loan-context/personalized-loan-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { appInsights } from '@lightspeed/utils/app-insights';
import { getLowestRateLoanProduct } from '@lightspeed/utils/get-lowest-rate-loan-product';
import { getLowestMonthlyPaymentLoanProduct } from '@lightspeed/utils/get-lowest-monthly-payment-loan-product';
import { useApplicationLead } from '@lightspeed/hooks/useApplicationLead/useApplicationLead';
import { chooseQuote } from '@lightspeed/utils/choose-quote';
import { FeatureFlags } from '@lightspeed/utils/feature-flags';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  maximumFractionDigits: 2,
  style: 'currency',
});

const rateFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 3,
  minimumFractionDigits: 3,
});

const processCompletePartialLead = async (completeLead: (selectedLoan?: IndividualPersonalizedLoan | undefined) => Promise<Response>, loanProduct: IndividualPersonalizedLoan) => {
  try {
    await completeLead(loanProduct);
  } catch (e) {
    if (e instanceof Error) {
      appInsights.trackException({
        exception: e,
      });
    }
    // eslint-disable-next-line no-alert
    alert('There was an issue submitting your application. Our engineers have been alerted and are looking into it.');
  }
};

function getHomeValue(mortgageApplication: MortgageApplicationStore) {
  const value = mortgageApplication.applicationType === 'Refinance' ? mortgageApplication.propertyHomeValue : mortgageApplication.propertyPurchasePrice;
  return currencyFormatter.format(parseInt(value, 10));
}

function getLoanAmount(mortgageApplication: MortgageApplicationStore) {
  if (mortgageApplication.applicationType === 'Refinance') {
    return currencyFormatter.format(
      parseInt(mortgageApplication.propertyMortgageBalance, 10) + parseInt(mortgageApplication.propertyCashOut, 10),
    );
  }

  return currencyFormatter.format(parseInt(mortgageApplication.propertyPurchasePrice, 10) - parseInt(mortgageApplication.propertyDownPayment, 10));
}

const generateSummaryData = (term: string, closestProduct: IndividualPersonalizedLoan, mortgageApplication: MortgageApplicationStore) => {
  const options: AccordionListProps = {
    options: [
      {
        title: 'Loan Details',
        values: [
          {
            label: 'Interest Rate',
            value: `${closestProduct.rate.toFixed(3)}%`,
          },
          {
            label: 'APR',
            value: `${closestProduct.apr.toFixed(3)}%`,
          },
          {
            label: 'Loan Term',
            value: `${term}-Year Fixed`,
          },
          {
            label: 'Discount Cost',
            value: currencyFormatter.format(closestProduct.discountCost),
          },
          {
            label: 'Est. Home Value',
            value: getHomeValue(mortgageApplication),
          },
          {
            label: 'Loan Amount',
            value: getLoanAmount(mortgageApplication),
          },
        ],
      },
      {
        title: 'Monthly Payment',
        values: [
          {
            label: 'Estimated Payment',
            value: currencyFormatter.format(closestProduct.estimatedMonthlyPayment),
          },
          {
            label: 'Estimated Principal & Interest',
            value: currencyFormatter.format(closestProduct.estimatedPrincipalAndInterest),
          },
          {
            label: 'Estimated PMI',
            value: currencyFormatter.format(closestProduct.estimatedPrivateMortgageInsurance),
          },
        ],
      },
      {
        title: 'Closing Costs',
        values: [
          {
            label: 'Initial Estimate',
            value: currencyFormatter.format(closestProduct.estimatedClosingCosts),
          },
        ],
      },
    ],
  };

  return options;
};

const discountPointOptions = [
  {
    label: '2',
    value: '2',
  },
  {
    label: '1.5',
    value: '1.5',
  },
  {
    label: '1',
    value: '1',
  },
  {
    label: '0.5',
    value: '0.5',
  },
  {
    label: '0',
    value: '0',
  },
];

export function RatesSelect() {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const goToNextRoute = useNextRoute(mortgageApplication);
  const fireAnalyticsEvent = useAnalytics('v2/rate-select');
  const { personalizedLoanOptions } = usePersonalizedLoan();
  const [loading, setLoading] = useState(false);
  const [loanProduct, setLoanProduct] = useState<IndividualPersonalizedLoan>();
  const { completeLead } = useApplicationLead();

  const yearAsNumber = {
    fifteen: '15',
    thirty: '30',
    twenty: '20',
  }[mortgageApplication.loanTerm];

  useEffect(() => {
    setLoanProduct(getClosestLoanProduct(personalizedLoanOptions.loanProducts, mortgageApplication.loanTerm, parseFloat(mortgageApplication.discountPoints)));
  }, [personalizedLoanOptions.loanProducts, mortgageApplication.loanTerm, mortgageApplication.discountPoints]);

  useEffect(() => {
    if (mortgageApplication.applicationType === 'Purchase') {
      const lp = getLowestRateLoanProduct(personalizedLoanOptions.loanProducts);
      updateMortgageApplication('discountPoints', (Math.round(lp.discountPoints * 2) / 2).toString()); // rounds to nearest .5
      updateMortgageApplication('loanTerm', lp.loanTerm.toLowerCase());
    }

    if (mortgageApplication.applicationType === 'Refinance') {
      if (mortgageApplication.reasonForRefinance === 'lowerPayment') {
        const lowestMonthlyPaymentLoanProduct = getLowestMonthlyPaymentLoanProduct(personalizedLoanOptions.loanProducts);
        updateMortgageApplication('loanTerm', lowestMonthlyPaymentLoanProduct.loanTerm.toLowerCase());
        updateMortgageApplication('discountPoints', (Math.round(lowestMonthlyPaymentLoanProduct.discountPoints * 2) / 2).toString());
      } else {
        const lowestRateLoanProduct = getLowestRateLoanProduct(personalizedLoanOptions.loanProducts);
        updateMortgageApplication('loanTerm', lowestRateLoanProduct.loanTerm.toLowerCase());
        updateMortgageApplication('discountPoints', (Math.round(lowestRateLoanProduct.discountPoints * 2) / 2).toString());
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let summaryData: AccordionListProps = {
    options: [],
  };
  if (loanProduct) {
    summaryData = generateSummaryData(yearAsNumber, loanProduct, mortgageApplication);
  }

  const onClick = () => {
    (async () => {
      if (loanProduct) {
        setLoading(true);
        if (FeatureFlags.FEATURE_NEW_BACKEND) {
          try {
            if (loanProduct.id) {
              await chooseQuote(mortgageApplication.quotingApplicationSubmissionId, loanProduct.id);
            } else {
              throw new Error('attempting to choose quote with no id');
            }
          } catch (e) {
            if (e instanceof Error) {
              appInsights.trackException({
                exception: e,
              });
            }
            // eslint-disable-next-line no-alert
            alert('There was an issue submitting your application. Our engineers have been alerted and are looking into it.');
          }
        } else {
          await processCompletePartialLead(completeLead, loanProduct);
        }
        fireAnalyticsEvent('next_button_success');
        goToNextRoute();
      }
    })();
  };

  return (
    <>
      <OptionalElement show={loading}>
        <LoadingModal />
      </OptionalElement>
      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <Form>
                    <Heading
                      element={'h1'}
                    >
                      Check out your lower rate.
                    </Heading>
                    <Heading
                      element={'h4'}
                    >
                      Any changes?
                    </Heading>
                    <TermSelect
                      value={mortgageApplication.loanTerm}
                      onChange={(v) => {
                        updateMortgageApplication('loanTerm', v);
                      }}
                      termsToShow={new Set<'thirty' | 'twenty' | 'fifteen'>(personalizedLoanOptions.loanProducts.map((lp) => lp.loanTerm.toLowerCase() as 'thirty' | 'twenty' | 'fifteen')) /* eslint-disable-line @typescript-eslint/consistent-type-assertions */}
                    />
                    <FormRow
                      style={{
                        gap: '32px',
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        <CircleButtonSelect
                          label={{
                            text: 'Discount Points',
                            tooltip: 'You can lower your interest rate by purchasing discount points. You buy discount points when your loan closes so you can pay less each month, and less over time.',
                          }}
                          onChange={(v: string) => { updateMortgageApplication('discountPoints', v); }}
                          value={mortgageApplication.discountPoints}
                          options={discountPointOptions}
                        />
                      </div>
                      <Separator vertical />
                      <div
                        style={{
                          flex: 1,
                          flexGrow: 2,
                        }}
                      >
                        <Label>
                          <span>
                            Discount Cost
                            <ToolTip bubble={'You can pay less up front for a higher interest rate. We’ll cover part of your closing costs through lending credits when your loan closes.'}>
                              <Symbol type={'Info'} />
                            </ToolTip>
                          </span>
                        </Label>
                        <Heading
                          element={'h4'}
                          marginTop={'24px'}
                        >
                          {currencyFormatter.format(loanProduct ? loanProduct.discountCost : 0)}
                        </Heading>
                      </div>
                    </FormRow>
                    <FormRow>
                      <Button
                        type={'button'}
                        onClick={onClick}
                      >
                        I want this rate
                      </Button>
                    </FormRow>
                  </Form>
                  <SplitSection.LegalFooter>
                    <Paragraph variant={'legal'}>
                      Your actual rate, payment, and costs could be higher. Get an official Loan Estimate before choosing a loan. Interest rates disclosed are subject to change without notice. Rates disclosed may not be available at time of rate lock.
                    </Paragraph>
                  </SplitSection.LegalFooter>
                </SplitSection.Content>
                <SplitSection.Sidebar>
                  <Scoreboard
                    marginY={'36px'}
                    label={'Summary'}
                    suffix={'%'}
                    value={loanProduct ? rateFormatter.format(loanProduct.rate) : '-.---'}
                  />
                  <AccordionList
                    options={summaryData?.options}
                    defaultSelectedIndexes={[0]}
                  />
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}
        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <Form>
                <Heading
                  element={'h1'}
                >
                  Check out your lower rate.
                </Heading>
                <Scoreboard
                  marginY={'20px'}
                  label={'Interest Rate'}
                  suffix={'%'}
                  value={loanProduct ? rateFormatter.format(loanProduct.rate) : '-.---'}
                />
                <Heading
                  element={'h4'}
                >
                  Any changes?
                </Heading>
                <SliderWrapper>
                  <TermSelectSlider>
                    <TermSelect
                      value={mortgageApplication.loanTerm}
                      onChange={(v) => {
                        updateMortgageApplication('loanTerm', v);
                      }}
                      termsToShow={new Set<'thirty' | 'twenty' | 'fifteen'>(personalizedLoanOptions.loanProducts.map((lp) =>  lp.loanTerm.toLowerCase() as 'thirty' | 'twenty' | 'fifteen')) /* eslint-disable-line @typescript-eslint/consistent-type-assertions */}
                    />
                  </TermSelectSlider>
                </SliderWrapper>
                <CircleButtonSelect
                  label={{
                    text: 'Discount Points',
                    tooltip: 'You can lower your interest rate by purchasing discount points. You buy discount points when your loan closes so you can pay less each month, and less over time.',
                  }}
                  onChange={(v: string) => { updateMortgageApplication('discountPoints', v); }}
                  value={mortgageApplication.discountPoints}
                  options={discountPointOptions}
                />
                <FormRow>
                  <Label
                    flex={1}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                  >
                    <span>
                      Discount Cost
                      <ToolTip
                        bubble={'You can pay less up front for a higher interest rate. We’ll cover part of your closing costs through lending credits when your loan closes.'}
                      >
                        <Symbol type={'Info'} />
                      </ToolTip>
                    </span>
                    <DiscountCost>
                      {currencyFormatter.format(loanProduct ? loanProduct.discountCost : 0)}
                    </DiscountCost>
                  </Label>
                </FormRow>
              </Form>
              <AccordionList
                options={summaryData.options}
                defaultSelectedIndexes={[0]}
              />
              <Paragraph variant={'legal'}>
                Your actual rate, payment, and costs could be higher. Get an official Loan Estimate before choosing a loan.
                Interest rates disclosed are subject to change without notice. Rates disclosed may not be available at time of rate lock.
              </Paragraph>
              <div
                style={{
                  margin: '32px 0',
                }}
              >
                <SwipeButton
                  label={'I want this rate'}
                  onSwipe={onClick}
                >
                  I want this rate
                </SwipeButton>
              </div>
            </BasePageTemplate>
          </>
        )}
      />
    </>
  );
}

const DiscountCost = styled.span`
  font-size: 16px;
  color: #000;
  font-family: Gotham-Bold, sans-serif;
`;

const SliderWrapper = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TermSelectSlider = styled.div`
  width: 120vw;
`;
