import { ObjectSchema, ValidationError } from 'yup';
import { useState } from 'react';
import { ObjectShape } from 'yup/lib/object';
import { SchemaLike } from 'yup/lib/types';

export type ValidationResultSuccess = {
  success: true;
  errors?: never;
};

export type ValidationResultError = {
  success: false;
  errors: Map<string, string[]>
};

export type ValidationResult = ValidationResultSuccess|ValidationResultError;

interface ValidationOptions {
  keysToValidate?: string[];
  yupValidationContext?: { hasCoBorrower: boolean, hasDifferentCoBorrowerAddress?: boolean } // variables which can be accessed via .when in schema: https://github.com/jquense/yup#mixedwhenkeys-string--arraystring-builder-object--value-schema-schema-schema
}

export function useValidation<T extends ObjectShape>(
  schema: ObjectSchema<T>,
  options: ValidationOptions = {},
): [
    (obj: object) => ValidationResult,
    Map<string, string[]> | null,
  ] {
  const [errors, setErrors] = useState<Map<string, string[]>|null>(null);

  const validate = (objectToValidate: object): ValidationResult => {
    try {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const validationSchema = (options.keysToValidate ? schema.pick(options.keysToValidate) : schema) as SchemaLike;

      validationSchema.validateSync(
        objectToValidate,
        {
          abortEarly: false,
          context: options.yupValidationContext,
        },
      );

      setErrors(null);

      return {
        success: true,
      };
    } catch (e) {
      if (e instanceof ValidationError) {
        // Path should always be set
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const errorMap = new Map(e.inner.map((err) => [err.params?.path as string, err.errors]));

        setErrors(errorMap);

        return {
          errors: errorMap,
          success: false,
        };
      }

      throw e;
    }
  };

  return [validate, errors];
}
