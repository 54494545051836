import styled from 'styled-components';
import { Heading, Paragraph, useModal } from '@lower/core-components';
import { useState } from 'react';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { SiteFooter } from '@lightspeed/components/ui/organisms/site-footer';
import LowerAvatarGroup from '@lightspeed/assets/images/lower-avatar-group.png';
import { VideoModal } from '@lightspeed/components/ui/organisms/video-modal/video-modal';
import Video1Thumbnail from '@lightspeed/assets/images/john-s-thumbnail.png';
import Video2Thumbnail from '@lightspeed/assets/images/brandi-t-thumbnail.png';
import Video3Thumbnail from '@lightspeed/assets/images/cory-w-thumbnail.png';
import Video4Thumbnail from '@lightspeed/assets/images/paul-m-thumbnail.png';
import TrustPilotSVG from '@lightspeed/assets/images/trust-pilot.png';
import Video1 from '@lightspeed/assets/videos/john-s.mp4';
import Video2 from '@lightspeed/assets/videos/brandi-t.mp4';
import Video3 from '@lightspeed/assets/videos/cory-w.mp4';
import Video4 from '@lightspeed/assets/videos/paul-m.mp4';

interface VideoMapping {
  videoSrc: string;
  thumbnailImgSrc: string;
}

const videoMapArray: VideoMapping[] = [
  {
    thumbnailImgSrc: Video1Thumbnail,
    videoSrc: Video1,
  },
  {
    thumbnailImgSrc: Video2Thumbnail,
    videoSrc: Video2,
  },
  {
    thumbnailImgSrc: Video3Thumbnail,
    videoSrc: Video3,
  },
  {
    thumbnailImgSrc: Video4Thumbnail,
    videoSrc: Video4,
  },
];

export function NonPreapprovalSuccess() {
  const [shouldShowVideoModal, show, hide] = useModal();
  const [video, setVideo] = useState<VideoMapping|null>(null);

  return (
    <>
      <BasePageTemplate>
        <Header />
        <NonPreapprovalSuccessContainer>
          <div
            style={
              {
                maxWidth: '318px',
              }
            }
          >
            <img
              src={LowerAvatarGroup}
              width={'100%'}
              alt={'Lower Avatars'}
            />
          </div>
          <TitleHeader >Success! We got your application.</TitleHeader>
          <Paragraph
            variant={'largeLight'}
            textAlign={'center'}
            marginTop={'8px'}
          >Sit tight. We’ll be reaching out soon.
          </Paragraph>
          <Paragraph
            textAlign={'center'}
            marginTop={'70px'}
          >
            <img
              src={TrustPilotSVG}
              alt={'Trust Pilot rating'}
            />
          </Paragraph>
          <VideoGroupContainer>
            {videoMapArray.map(({ videoSrc, thumbnailImgSrc }) => (
              <VideoCard key={videoSrc}>
                <LaunchVideoButton
                  title={'Launch customer review video.'}
                  onClick={() => {
                    setVideo({
                      thumbnailImgSrc,
                      videoSrc,
                    });
                    show();
                  }}
                >
                  <ThumbNail
                    src={thumbnailImgSrc}
                  />
                </LaunchVideoButton>
              </VideoCard>
            ))}
          </VideoGroupContainer>
        </NonPreapprovalSuccessContainer>
        <VideoModal
          videoSrc={video?.videoSrc}
          thumbnailImgSrc={video?.thumbnailImgSrc}
          showModal={shouldShowVideoModal}
          onClose={hide}
        />
      </BasePageTemplate>
      <SiteFooter />
    </>
  );
}
const NonPreapprovalSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TitleHeader = styled(Heading)`
  display: flex;  
  padding-bottom: 16px;
  padding-top: 24px;

  /* font styles mobile */
  font-family: ${(props) => props.theme.Headers.h3};
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #1D1D23;
  ${(props) => props.theme.media.desktop} {
    /* font styles desktop */
    font-family: ${(props) => props.theme.Headers.h1};
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 72px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #1D1D23;
  }
`;

const VideoGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 48px;
  width: 100%;
  max-width: 80%;
  gap: 20px 40px;
`;

const VideoCard = styled.div`
  flex: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 173px;
  height: 300px;
  border-radius: 16px;
  box-shadow: 0 24px 48px rgba(23, 23, 47, 0.1);
`;

const ThumbNail = styled.img`
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%
`;

const LaunchVideoButton = styled.button`
  display: none;
  all: unset;
  cursor: pointer;
`;
