import styled from 'styled-components';
import { compose, fontWeight, FontWeightProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import React from 'react';
import cx from 'classnames';
import { ThemedProps } from '../../styles/utils/theme-utils';

export type ButtonProps = {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'link';
} & FontWeightProps & LayoutProps & SpaceProps;

export const Button = styled.button.attrs(({
  className,
  type = 'button',
  variant = 'primary',
  ...props
}: ButtonProps) => ({
  className: cx('Button', className, `--${variant}`, {
    'is-disabled': props.disabled,
  }),
  type,
  variant,
  ...props,
}))<ButtonProps>`
  /* Reset */
  all: unset;
  cursor: pointer;
  box-sizing: border-box;

  padding: 19px 30px;
  ${ (props: ThemedProps) => props.theme.Button.bold };
  border-radius: 100px;
  box-shadow: ${ (props: ThemedProps) => props.theme.shadows.generic };
  text-align: center;

  ${(props: ThemedProps) => props.theme.media.desktop} {
    border-radius: 100px;
  }

  &.--primary {
    color: ${ ({ theme }: ThemedProps) => theme.colors.white[0] };
    background-color: ${ ({ theme }: ThemedProps) => theme.colors.blue[0] };

    &:hover:not(:active):not(.is-disabled):not(:disabled) {
      transform: scale(1.05);
      background-color: ${ (props: ThemedProps) => props.theme.colors.lightBlue };
    }

    &:active:not(.is-disabled):not(:disabled) {
      transform: scale(.95)
    }
  }

  &.--secondary {
    color: ${ ({ theme }: ThemedProps) => theme.colors.blue[0] };
    background-color: ${ ({ theme }: ThemedProps) => theme.colors.blue[1] };

  }

  &.--link {
    display: inline;
    text-align: left;
    color: ${ ({ theme }: ThemedProps) => theme.colors.blue[0] };
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }

  &.is-disabled, &:disabled {
    cursor: not-allowed;
    color: ${ (props: ThemedProps) => props.theme.colors.white[1] };
  }

  &:focus-visible {
    border: 1px solid ${ (props: ThemedProps) => props.theme.colors.blue[1] };
  }

  ${ compose(layout, space, fontWeight) };
`;
