import React, { useEffect } from 'react';
import { useAnalytics } from '@lower/analytics';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { BasicInfoTemplate } from './basic-info/basic-info-template';

export function Heloc() {
  const { updateMortgageApplication } = useMortgageApplication();
  const fireAnalyticsEvent = useAnalytics('v2/heloc');

  useEffect(() => {
    updateMortgageApplication('applicationType', 'Heloc');
  }, [updateMortgageApplication]);

  return (
    <BasicInfoTemplate
      fireAnalyticsEvent={fireAnalyticsEvent}
    />
  );
}
