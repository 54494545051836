import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useMortgageApplication,
} from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { ROUTES } from '@lightspeed/routing/routing-machine';
import { IndividualPersonalizedLoan } from '@lightspeed/api/services/personalizedLoanProducts/bestavailable';
import { useApplicationLead } from '@lightspeed/hooks/useApplicationLead/useApplicationLead';
import { useSubmitQuotingApplication } from '@lightspeed/hooks/useSubmitQuotingApplication/useSubmitQuotingApplication';
import { FeatureFlags } from '@lightspeed/utils/feature-flags';
import { useProgress } from './use-progress';
import { ProcessingTemplate } from './processing-template';

const textPrompts = [
  'Plugging in your numbers...',
  'Uploading income...',
  'Checking assets...',
  'Confirming identity...',
  'Running soft credit pull...',
];

const legacyProcessSubmission = async (navigate: (v: string) => void, completeLead: (selectedLoan?: IndividualPersonalizedLoan | undefined) => Promise<Response>) => {
  try {
    await completeLead();
  } catch (e) {
    navigate(ROUTES.ERROR);
  }
};

const processSubmission = async (navigate: (v: string) => void, completeLead: () => Promise<string | undefined>) => {
  try {
    await completeLead();
  } catch (e) {
    navigate(ROUTES.ERROR);
  }
};

export function ProcessingLeadSubmission() {
  const navigate = useNavigate();
  const { mortgageApplication } = useMortgageApplication();
  const goToNextRoute = useNextRoute(mortgageApplication);
  const [hasCompleted, setHasCompleted] = useState(false);
  const { completeLead: legacyCompleteLead } = useApplicationLead();
  const completeLead = useSubmitQuotingApplication();

  useEffect(() => {
    if (hasCompleted) {
      goToNextRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCompleted]);

  const onSubmissionComplete = useCallback(() => {
    setHasCompleted(true);
  }, [setHasCompleted]);

  const [progress, prompt] = useProgress(
    textPrompts,
    () => (FeatureFlags.FEATURE_NEW_BACKEND ? processSubmission(navigate, completeLead) : legacyProcessSubmission(navigate, legacyCompleteLead)),
    onSubmissionComplete,
  );

  return (
    <ProcessingTemplate
      progress={progress}
      prompt={prompt}
    />
  );
}
