import { CardSelect } from '@lightspeed/components/ui/organisms';
import { CardSelectOption } from '@lightspeed/components/ui/organisms/card-select/card-select';

export const TermSelect = ({
  value,
  onChange = () => {
    // do nothing
  },
  termsToShow = new Set<'thirty' | 'twenty' | 'fifteen'>(['thirty', 'twenty', 'fifteen']),
}: { value: string, onChange?: (v: string) => void, termsToShow?: Set<'thirty' | 'twenty' | 'fifteen'> }) => {
  const options: CardSelectOption[] = [];
  if (termsToShow.has('thirty')) {
    options.push({
      label: '30-Year',
      tag: {
        text: '$',
        variant: 'teal',
      },
      value: 'thirty',
    });
  }

  if (termsToShow.has('twenty')) {
    options.push({
      label: '20-Year',
      tag: {
        text: '$$',
        variant: 'blue',
      },
      value: 'twenty',
    });
  }

  if (termsToShow.has('fifteen')) {
    options.push({
      label: '15-Year',
      tag: {
        text: '$$$',
        variant: 'purple',
      },
      value: 'fifteen',
    });
  }

  return (
    <CardSelect
      onChange={onChange}
      value={value}
      options={options}
    />
  );
};
