import { quotingDecision } from '@lightspeed/api/services/new-backend/lending/quotingDecision';
import { appInsights } from '@lightspeed/utils/app-insights';

export const chooseQuote = async (quotingApplicationSubmissionId: string, quoteId: string) => {
  try {
    const response = await quotingDecision(quotingApplicationSubmissionId, quoteId);
    if (response.status !== 204) {
      throw new Error('failed to choose quote');
    }
  } catch {
    const error = new Error('failed to choose quote');
    appInsights.trackException({
      exception: error,
    });
    throw error;
  }
};
