import { useState, useEffect } from 'react';
import todaysRates from '@lightspeed/api/services/content/todays-rates';
import { isKeyof } from '@lightspeed/utils/type-assertions';

export interface Rate {
  term: number,
  apr: number,
  discountPoints: number,
  loanAmount: number,
  rate: number,
  payment: number,
  loanType: 'refinance'|'purchase';
}

interface MortgageTerm {
  'refinance': Partial<Rate>,
  'purchase': Partial<Rate>,
}

export interface TodaysRates {
  'fifteen': MortgageTerm,
  'twenty': MortgageTerm,
  'thirty': MortgageTerm,
}

const termMap = new Map<number, string>([
  [15, 'fifteen'],
  [20, 'twenty'],
  [30, 'thirty'],
]);

export default function useTodaysRates(): TodaysRates|null {
  const [rates, setRates] = useState<TodaysRates|null>(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await todaysRates();

        const networkRates = await response.json();

        const formattedRates: TodaysRates = {
          fifteen: {
            purchase: {},
            refinance: {},
          },
          thirty: {
            purchase: {},
            refinance: {},
          },
          twenty: {
            purchase: {},
            refinance: {},
          },
        };

        networkRates.forEach((r) => {
          const termKey = termMap.get(r.term);
          if (!termKey) { return; }

          const { loanType } = r;

          if (isKeyof(termKey, formattedRates)) {
            formattedRates[termKey][loanType] = r;
          }
        });

        setRates(formattedRates);
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert('There was an issue loading the page. Please refresh.');
      }
    })();
  }, []);

  return rates;
}
