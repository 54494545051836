import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AsyncReturnType } from 'type-fest';
import { useAnalytics } from '@lower/analytics';
import {
  MortgageApplicationStore,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { usePersonalizedLoan } from '@lightspeed/contexts/personalized-loan-context/personalized-loan-context';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { IndividualPersonalizedLoan } from '@lightspeed/api/services/personalizedLoanProducts/bestavailable';
import { PersonalizedLoanStore } from '@lightspeed/contexts/personalized-loan-context/use-personalized-loan-state';
import { appInsights } from '@lightspeed/utils/app-insights';
import { TERM_MAP } from '@lightspeed/utils/get-closest-loan-product';
import { ROUTES } from '@lightspeed/routing/routing-machine';
import { useApplicationLead } from '@lightspeed/hooks/useApplicationLead/useApplicationLead';
import { FeatureFlags } from '@lightspeed/utils/feature-flags';
import { useSubmitQuotingApplication } from '@lightspeed/hooks/useSubmitQuotingApplication/useSubmitQuotingApplication';
import { pollForQuotes } from '@lightspeed/utils/poll-for-quotes';
import { getLoanProducts } from '@lightspeed/utils/get-loan-products';
import { useProgress } from './use-progress';
import { ProcessingTemplate } from './processing-template';

const textPrompts = [
  'Plugging in your numbers...',
  'Uploading income...',
  'Checking assets...',
  'Confirming identity...',
  'Running soft credit pull...',
  'Building rate (Exciting!)',
];

interface GetPersonalizedLoansProps {
  completeLead: (selectedLoan?: IndividualPersonalizedLoan | undefined) => Promise<Response>;
  fireAnalyticsEvent: (eventName: string, eventMetaData?: object) => void;
  mortgageApplication: MortgageApplicationStore;
  updatePersonalizedLoan: (updatedState: Partial<PersonalizedLoanStore>) => void;
  navigate: (v: string) => void;
}

const completePartialLead = async (completeLead: (selectedLoan?: IndividualPersonalizedLoan | undefined) => Promise<Response>, fireAnalyticsEvent: (eventName: string, eventMetaData?: object) => void) => {
  try {
    await completeLead();
    fireAnalyticsEvent('completed_partial_lead');
  } catch (e) {
    if (e instanceof Error) {
      appInsights.trackException({
        exception: e,
      });
    }
    throw new Error('Complete Partial Lead Failed');
  }
};

const getPersonalizedLoans = async ({
  completeLead,
  fireAnalyticsEvent,
  mortgageApplication,
  updatePersonalizedLoan,
  navigate,
}: GetPersonalizedLoansProps) => {
  try {
    const loanProductsResult = await getLoanProducts(mortgageApplication);
    updatePersonalizedLoan(loanProductsResult);
    return loanProductsResult?.loanProducts;
  } catch {
    try {
      await completePartialLead(completeLead, fireAnalyticsEvent);
    } catch (e) {
      appInsights.trackException({
        exception: new Error('On Processing Rates, both bestavailable and submit lead endpoints failed.'),
      });
      navigate(ROUTES.ERROR);
    }
  }
  return [];
};

export function ProcessingRates() {
  const navigate = useNavigate();
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const { personalizedLoanOptions, updatePersonalizedLoan } = usePersonalizedLoan();
  const [hasCompleted, setHasCompleted] = useState(false);
  const goToNextRoute = useNextRoute(mortgageApplication, personalizedLoanOptions);
  const fireAnalyticsEvent = useAnalytics('v2/processing_rates');
  const { completeLead } = useApplicationLead();
  const submitQuotingApplication = useSubmitQuotingApplication();
  useEffect(() => {
    if (hasCompleted) {
      goToNextRoute();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCompleted]);

  const asyncAction = async () => {
    let loanProducts!: AsyncReturnType<typeof pollForQuotes> | AsyncReturnType<typeof getPersonalizedLoans>;
    if (FeatureFlags.FEATURE_NEW_BACKEND) {
      const quotingApplicationSubmissionId = await submitQuotingApplication();

      if (quotingApplicationSubmissionId) {
        loanProducts = await pollForQuotes(quotingApplicationSubmissionId, fireAnalyticsEvent);
      }
    } else {
      loanProducts = await getPersonalizedLoans({
        completeLead,
        fireAnalyticsEvent,
        mortgageApplication,
        navigate,
        updatePersonalizedLoan,
      });
    }
    if (loanProducts === undefined) { return; }
    if (loanProducts?.length === 0) {
      fireAnalyticsEvent('no_loan_products');
      return;
    }

    fireAnalyticsEvent('received_loan_products');

    updatePersonalizedLoan({
      loanProducts,
      passedFilters: true, // TODO(quinton): do this based on a value from the backend
    });

    const loanProductExistsForSelectedTerm =
      loanProducts?.some((lp) => lp.loanTerm === TERM_MAP[mortgageApplication.loanTerm]);

    // rare case where no loan product is returned for the currently selected term. not sure this will happen in production.
    if (!loanProductExistsForSelectedTerm) {
      const firstThirtyYearLoanProduct = loanProducts.find((lp) => lp.loanTerm === 'Thirty');
      if (firstThirtyYearLoanProduct) {
        updateMortgageApplication('loanTerm', 'thirty');
        return;
      }
      const firstFifteenYearLoanProduct = loanProducts.filter((lp) => lp.loanTerm === 'Fifteen')[0];
      if (firstFifteenYearLoanProduct) {
        updateMortgageApplication('loanTerm', 'fifteen');
        return;
      }
      const firstTwentyYearLoanProduct = loanProducts.filter((lp) => lp.loanTerm === 'Twenty')[0];
      if (firstTwentyYearLoanProduct) {
        updateMortgageApplication('loanTerm', 'twenty');
      }
    }
  };

  const completeAsyncAction = useCallback(() => {
    setHasCompleted(true);
  }, [setHasCompleted]);

  const [progress, prompt] = useProgress(
    textPrompts,
    asyncAction,
    completeAsyncAction,
    15,
  );

  return (
    <ProcessingTemplate
      progress={progress}
      prompt={prompt}
    />
  );
}
