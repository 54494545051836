import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { DropdownArrow } from '@lower/icons';
import { responsiveStyleFromTheme, ThemedProps } from '../../styles/utils/theme-utils';

export interface AccordionProps {
  active?: boolean;
  disabled?: boolean;
  className?: string;
  label?: string;
  onClick?: () => void;
  children?: ReactNode;
}

const Container = styled.div`
  width: 100%;
`;

const Thumbnail = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const ArrowIndicator = styled.div<{ open?: boolean }>`
  ${({ theme, open }) => `
    transition: transform 0.3s ease-in-out;
    position: relative;
    width: 9px;
    height: 5px;
    padding-left: 10px;
    ${open ? `
      transform: rotate(180deg);
    ` : `
      transform: rotate(0deg);
    `};
  `}
`;

const Panel = styled.div<{ open?: boolean }>`
  ${({ theme, open }) => `
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
    ${open ? `
      max-height: 100rem;
    ` : `
    `};
  `}
`;

const PanelContent = styled.div<{ open?: boolean }>`
  ${({ theme, open }) => `
    opacity: 0;
    transform: translateY(-0.5rem);
    transition-delay: 0.2s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    ${open ? `
      opacity: 1;
      transform: translateY(0);
    ` : `
    `};
  `}
`;

const ThumbnailLabel = styled.p((props: ThemedProps) => css`
  flex: 1;
  ${responsiveStyleFromTheme(props.theme.Headers.h5)};
`);

export const DropdownArrowImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%) translateY(-50%);
`;

export const Accordion = ({
  active,
  disabled,
  children,
  label,
  className,
  onClick,
  ...props
}: AccordionProps) => (
  <Container>
    <Thumbnail onClick={onClick}>
      <ThumbnailLabel>{label}</ThumbnailLabel>
      <ArrowIndicator open={active}>
        <DropdownArrowImg src={DropdownArrow} />
      </ArrowIndicator>
    </Thumbnail>
    <Panel
      data-testid={'panel'}
      open={active}
    >
      <PanelContent open={active}>
        {children && children}
      </PanelContent>
    </Panel>
  </Container>
);
