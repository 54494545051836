import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { DropdownArrow, HelpCircle } from '@lower/icons';
import { useModal } from '../../hooks/useModal';
import { responsiveStyleFromTheme, ThemedProps } from '../../styles/utils/theme-utils';

export interface ExplainerProps {
  disabled?: boolean;
  label?: string;
  children?: ReactNode;
}

const Container = styled.div`
  width: 100%;
`;

const Thumbnail = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ArrowIndicator = styled.div<{ open?: boolean }>`
  ${({ open }) => `
    transition: transform 0.3s ease-in-out;
    position: relative;
    width: 9px;
    height: 5px;
    padding-left: 10px;
    ${open ? `
      transform: rotate(180deg);
    ` : `
      transform: rotate(0deg);
    `};
  `}
  ${
  responsiveStyleFromTheme({
    desktop: {
      display: 'none',
    },
  })
}
`;

const Panel = styled.div<{ open?: boolean }>`
  ${({ theme, open }) => `
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
    margin-top: 12px;
    ${open ? `
      max-height: 100rem;
    ` : `
    `};
  `}
  ${
  responsiveStyleFromTheme({
    desktop: {
      maxHeight: '100rem',
    },
  })
}
`;

const PanelContent = styled.div<{ open?: boolean }>`
  ${({ theme, open }) => `
    opacity: 0;
    transform: translateY(-0.5rem);
    transition-delay: 0.2s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    ${open ? `
      opacity: 1;
      transform: translateY(0);
    ` : `
    `};
  `}
  ${
  responsiveStyleFromTheme({
    desktop: {
      opacity: '1',
      transform: 'translateY(0)',
    },
  })
}
`;

const ThumbnailLabel = styled.p((props: ThemedProps) => css`
  ${responsiveStyleFromTheme(props.theme.Headers.h5)};
  margin: 0;
  padding: 0;
`);

export const DropdownArrowImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%) translateY(-50%);
`;

export const Explainer = ({
  disabled,
  children,
  label,
  ...props
}: ExplainerProps) => {
  const [active, , , toggle] = useModal();

  return (
    <Container>
      <Thumbnail onClick={toggle}>
        <img
          src={HelpCircle}
          alt={'icon'}
        />
        <ThumbnailLabel>{label}</ThumbnailLabel>
        <ArrowIndicator open={active}>
          <DropdownArrowImg src={DropdownArrow} />
        </ArrowIndicator>
      </Thumbnail>
      <Panel
        data-testid={'panel'}
        open={active}
      >
        <PanelContent open={active}>
          {children && children}
        </PanelContent>
      </Panel>
    </Container>
  );
};
