import { ENV } from '@lightspeed/environment';
import { fetch, TypedResponse } from '@lightspeed/utils/fetch';

interface PurchaseLoan {
  purpose: 'Purchase';
  amount: number;
}

interface RefinanceLoan {
  purpose: 'Refinance';
  amount: number;
  cashOutAmount: number;
}

export type Loan = PurchaseLoan | RefinanceLoan;

/**
 * Property address different than address interface
 * due to street and city being optional
 */
export interface Property {
  occupancy: 'PrimaryResidence'|'SecondHome'|'Investment';
  propertyType: 'SingleFamily'|'Condo'|'Duplex'|'Triplex'|'Fourplex';
  value: number;
  address: {
    street?: string|null;
    city?: string|null;
    state: string; // abbreviation
    zipCode: string;
    county: string;
  };
}

interface Address {
  street: string;
  city: string;
  state: string; // abbreviation
  zipCode: string;
  county: string;
}

interface PersonalInformation {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  ssn: string;
  employmentStatus: 'W2'|'SelfEmployed'|'Retired';
  address: Address;
}

interface FinancialInformation {
  yearlySalary: number;
  otherIncomePerYear: number;
}

interface CreditAuthorization {
  referenceId: string;
  authorizedAt: string; // ISO-8601 date string
}

export interface CoBorrower {
  personalInformation: PersonalInformation;
  financialInformation: FinancialInformation;
  creditAuthorization: CreditAuthorization;
}

export interface Borrower extends CoBorrower {
  firstTimeHomeBuyer: boolean;
}

export interface BestAvailablePayload {
  loan: Loan;
  property: Property;
  borrower: Borrower;
  coBorrower?: CoBorrower;
}

export interface IndividualPersonalizedLoan {
  apr: number,
  discountCost: number,
  discountPoints: number,
  estimatedClosingCosts: number,
  estimatedMonthlyPayment: number,
  estimatedPrincipalAndInterest: number,
  estimatedPrivateMortgageInsurance: number,
  loanTerm: string,
  rate: number,
  id?: string,
}

export interface BestAvailableResponse {
  loanProducts: Array<IndividualPersonalizedLoan>,
}

export default function bestavailable(payload: BestAvailablePayload) {
  return fetch<TypedResponse<BestAvailableResponse>>(`${ENV.API_HOST}/api/v1/PersonalizedLoanProducts/bestavailable`, {
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
}
