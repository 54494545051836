type FetchParams = Parameters<typeof window.fetch>;

export type TypedResponse<JsonBodyType, StatusCode extends number = 200> = {
  json: () => Promise<JsonBodyType>;
  status: StatusCode;
} & Response;

export type EmptyResponse<StatusCode extends number = 200> = TypedResponse<never, StatusCode>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const fetch = <T extends Response>(...params: FetchParams) => window
  .fetch(...params) as Promise<T>;
