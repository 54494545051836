import { CreateLinkTokenResponse, ErrorResponse } from '@lower-web-api/generated';
import { ENV } from '@lightspeed/environment';
import { fetch, TypedResponse } from '@lightspeed/utils/fetch';

export function createPlaidLinkToken(id: string): Promise<LinkTokenResponse> {
  return fetch<LinkTokenResponse>(
    `${ENV.NEW_BACKEND_API_HOST}/api/lending/quoting_applications/${id}/create_link_token`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );
}

type NetworkErrorResponse = TypedResponse<ErrorResponse, 400>;
type CreatedResponse = TypedResponse<CreateLinkTokenResponse, 200>;

export type LinkTokenResponse = NetworkErrorResponse | CreatedResponse;
