import styled from 'styled-components';

export interface CircleContainerProps {
  background: string;
  circleSize?: string;
}

export interface IconCircleProps extends CircleContainerProps {
  icon: string;
  testId?: string;
  altText?: string;
}

export const IconCircle = ({
  icon, background, testId = 'icon-circle', circleSize = '40px', altText,
}: IconCircleProps) => (
  <CircleContainer
    background={background}
    data-testid={testId}
    circleSize={circleSize}
  >
    <IconImg
      alt={altText ?? 'Circle Icon'}
      src={icon}
    />
  </CircleContainer>
);

export const IconImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const CircleContainer = styled.div`
  width: ${(props: CircleContainerProps) => props.circleSize};
  height: ${(props: CircleContainerProps) => props.circleSize};
  border-radius: 50%;
  background: ${(props: CircleContainerProps) => props.background};
  position: relative;
`;
