/* tslint:disable */
/* eslint-disable */
/**
 * Lower API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EmploymentType {
    W2 = 'w2',
    SelfEmployed = 'selfEmployed',
    Retired = 'retired'
}

export function EmploymentTypeFromJSON(json: any): EmploymentType {
    return EmploymentTypeFromJSONTyped(json, false);
}

export function EmploymentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmploymentType {
    return json as EmploymentType;
}

export function EmploymentTypeToJSON(value?: EmploymentType | null): any {
    return value as any;
}

