import { ErrorResponse } from '@lower-web-api/generated';
import { ENV } from '@lightspeed/environment';
import { fetch, TypedResponse, EmptyResponse } from '@lightspeed/utils/fetch';

export function quotingDecision(quotingApplicationSubmissionId: string, quoteId: string): Promise<QuotingDecisionResponse> {
  return fetch<QuotingDecisionResponse>(
    `${ENV.NEW_BACKEND_API_HOST}/api/lending/quoting_application_submissions/${quotingApplicationSubmissionId}/quotes/${quoteId}/decision`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );
}

type SuccessfulDecisionInterface = EmptyResponse<204>;
type ErrorInterface = TypedResponse<ErrorResponse, 404|409>;

export type QuotingDecisionResponse = SuccessfulDecisionInterface | ErrorInterface;
