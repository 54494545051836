import createApplicationLead from '@lightspeed/api/services/leads/createApplicationLead';
import { IndividualPersonalizedLoan } from '@lightspeed/api/services/personalizedLoanProducts/bestavailable';
import { transformApplicationToLead } from '@lightspeed/transformers/partial-lead-transformer';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import updateApplicationLead from '@lightspeed/api/services/leads/updateApplicationLead';
import { appInsights } from '@lightspeed/utils/app-insights';
import { getReferralCookieData } from '@lightspeed/utils/get-referral-cookie-data';
import { transformMortgageApplicationToQuotingApplication } from '@lightspeed/transformers/quoting-application-transformer';
import { FeatureFlags } from '@lightspeed/utils/feature-flags';
import { createQuotingApplication } from '@lightspeed/api/services/new-backend/lending/createQuotingApplication';
import { updateQuotingApplication } from '@lightspeed/api/services/new-backend/lending/updateQuotingApplication';

export function useApplicationLead() {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const referralCookieData  = getReferralCookieData();

  const upsertLead = async (selectedLoan?: IndividualPersonalizedLoan | undefined) => {
    if (FeatureFlags.FEATURE_NEW_BACKEND) {
      return lowerWebApiUpsertLead();
    }

    return legacyUpsertLead(selectedLoan);
  };

  const legacyUpsertLead = async (selectedLoan?: IndividualPersonalizedLoan | undefined) => {
    const transformedApplication = transformApplicationToLead(mortgageApplication, referralCookieData, selectedLoan);

    if (mortgageApplication.leadId) {
      const updateResponse = await updateApplicationLead(mortgageApplication.leadId, transformedApplication);
      if (!updateResponse.ok) {
        const error =  new Error(`Couldnt create update partial lead: ${updateResponse.status}`);
        appInsights.trackException({
          exception: error,
        });
        throw error;
      }
    } else {
      const createResponse = await createApplicationLead(transformedApplication);
      if (createResponse.ok) {
        const json = await createResponse.json();

        updateMortgageApplication('leadId', json.leadId);
      } else {
        const error = new Error(`Couldnt create new partial lead: ${createResponse.status}`);
        appInsights.trackException({
          exception: error,
        });
        throw error;
      }
    }
  };

  const lowerWebApiUpsertLead = async () => {
    const quotingApplication = transformMortgageApplicationToQuotingApplication(mortgageApplication, referralCookieData);
    if (mortgageApplication.leadId) {
      const response = await updateQuotingApplication(mortgageApplication.leadId, quotingApplication);

      if (response.status === 404) {
        const error =  new Error(`Couldn't find application for lead id ${mortgageApplication.leadId}`);
        appInsights.trackException({
          exception: error,
        });
        throw error;
      } else if (response.status === 422) {
        const data = await response.json();
        const error =  new Error(`Error validating application for lead id ${mortgageApplication.leadId}: ${JSON.stringify(data.errors)}`);
        appInsights.trackException({
          exception: error,
        });
        throw error;
      } else if (response.status !== 204) {
        const error =  new Error(`Unexpected error submitting application for lead id ${mortgageApplication.leadId}`);
        appInsights.trackException({
          exception: error,
        }, {
          response,
        });
        throw error;
      }
    } else {
      try {
        const createResponse = await createQuotingApplication(quotingApplication);
        if (createResponse.status === 201) {
          const quoteData = await createResponse.json();
          if (quoteData.quotingApplicationId) {
            updateMortgageApplication('leadId', quoteData.quotingApplicationId);
          }
        } else {
          throw new Error('Couldnt create new quoting application');
        }
      } catch (e) {
        const error = new Error('Couldnt create new quoting application');
        appInsights.trackException({
          exception: error,
        });
        throw error;
      }
    }
  };

  const completeLead = async (selectedLoan?: IndividualPersonalizedLoan | undefined): Promise<Response> => {
    const transformedApplication = transformApplicationToLead(mortgageApplication, referralCookieData, selectedLoan, true);

    if (mortgageApplication.leadId) {
      const response = await updateApplicationLead(mortgageApplication.leadId, transformedApplication);
      if (response.ok) {
        return response;
      }
      const error = new Error(`Couldnt complete existing partial lead: ${response.status}`);
      appInsights.trackException({
        exception: error,
      });
      throw error;
    }

    const createResponse = await createApplicationLead(transformedApplication);
    if (createResponse.ok) {
      const json = await createResponse.json();

      updateMortgageApplication('leadId', json.leadId);
    } else {
      const error = new Error(`Couldnt complete new lead: ${createResponse.status}`);
      appInsights.trackException({
        exception: error,
      });
      throw error;
    }
    return createResponse;
  };

  return {
    completeLead,
    upsertLead,
  };
}
