import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { isLocalDev } from '@lightspeed/utils/local-dev';
import { ENV } from '@lightspeed/environment';

// eslint-disable-next-line import/no-mutable-exports
let appInsights: ApplicationInsights;
if (!isLocalDev()) {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: ENV.APPLICATION_INSIGHTS_CONNECTION_STRING,
    },
  });
  appInsights.loadAppInsights();
}

export {
  appInsights,
};
