/* eslint-disable sort-keys-fix/sort-keys-fix */
import { createMachine, EventObject } from 'xstate';
import { CONTRACT_STATUSES } from '@lightspeed/models/contract-statuses';

export interface RoutingContext {
  applicationType: 'Purchase' | 'Heloc' | 'Refinance';
  hasRates: boolean;
  borrowerContractStatus: CONTRACT_STATUSES | '';
  isUnsupportedMarket: boolean;
}

export enum ROUTES {
  LANDING = '/mortgage/lightspeed',
  PURCHASE = '/mortgage/lightspeed/purchase',
  REFINANCE = '/mortgage/lightspeed/refinance',
  HELOC = '/mortgage/lightspeed/heloc',
  CURRENT_ADDRESS_PURCHASE = '/mortgage/lightspeed/current-address/purchase',
  CURRENT_ADDRESS_NON_PURCHASE = '/mortgage/lightspeed/current-address/non-purchase',
  PROPERTY_PURCHASE_IN_CONTRACT = '/mortgage/lightspeed/property/purchase/in-contract',
  PROPERTY_PURCHASE_NOT_IN_CONTRACT = '/mortgage/lightspeed/property/purchase/not-in-contract',
  PROPERTY_NON_PURCHASE = '/mortgage/lightspeed/property/non-purchase',
  ASSETS = '/mortgage/lightspeed/assets',
  INCOME = '/mortgage/lightspeed/income',
  IDENTITY = '/mortgage/lightspeed/identity',
  PROCESSING_LEAD_SUBMISSION = '/mortgage/lightspeed/processing/lead-submission',
  PROCESSING_RATES = '/mortgage/lightspeed/processing/rates',
  RATES_SELECT = '/mortgage/lightspeed/rates/select',
  CONFETTI = '/mortgage/lightspeed/confetti',
  ERROR = '/mortgage/lightspeed/error',
  UNSUPPORTED_MARKET = '/mortgage/lightspeed/unsupported-states',
  NON_PREAPPROVAL_SUCCESS = '/mortgage/lightspeed/non-preapproval-success',
  ASSET_LINK = '/mortgage/lightspeed/assets/link',
}

export const RoutesPriorToMinimumInfo = new Set<string>([ROUTES.LANDING, ROUTES.PURCHASE, ROUTES.REFINANCE, ROUTES.HELOC]);

export enum GUARDS {
  isHeloc = 'isHeloc',
  isPurchase = 'isPurchase',
  isRefinance = 'isRefinance',
  hasRates = 'hasRates',
  inContract = 'inContract',
  isUnsupportedMarket = 'isUnsupportedMarket',
}

export enum ROUTING_EVENTS {
  NEXT = 'NEXT',
}

interface RoutingEvent extends EventObject {
  type: ROUTING_EVENTS,
}

export const RoutingMachine = createMachine<RoutingContext, RoutingEvent>({
  id: 'routing',
  initial: ROUTES.PURCHASE,
  context: {
    applicationType: 'Purchase',
    hasRates: false,
    borrowerContractStatus: '',
    isUnsupportedMarket: false,
  },
  states: {
    [ROUTES.LANDING]: {
      on: {
        [ROUTING_EVENTS.NEXT]: [
          {
            target: ROUTES.PURCHASE,
            cond: GUARDS.isPurchase,
          },
          {
            target: ROUTES.REFINANCE,
            cond: GUARDS.isRefinance,
          },
          {
            target: ROUTES.HELOC,
          },
        ],
      },
    },
    [ROUTES.PURCHASE]: {
      on: {
        [ROUTING_EVENTS.NEXT]: [
          {
            target: ROUTES.UNSUPPORTED_MARKET,
            cond: GUARDS.isUnsupportedMarket,
          },
          {
            target: ROUTES.CURRENT_ADDRESS_PURCHASE,
          },
        ],
      },
    },
    [ROUTES.REFINANCE]: {
      on: {
        [ROUTING_EVENTS.NEXT]: [
          {
            target: ROUTES.UNSUPPORTED_MARKET,
            cond: GUARDS.isUnsupportedMarket,
          },
          {
            target: ROUTES.CURRENT_ADDRESS_NON_PURCHASE,
          },
        ],
      },
    },
    [ROUTES.HELOC]: {
      on: {
        [ROUTING_EVENTS.NEXT]: [
          {
            target: ROUTES.UNSUPPORTED_MARKET,
            cond: GUARDS.isUnsupportedMarket,
          },
          {
            target: ROUTES.CURRENT_ADDRESS_NON_PURCHASE,
          },
        ],
      },
    },
    [ROUTES.CURRENT_ADDRESS_PURCHASE]: {
      on: {
        [ROUTING_EVENTS.NEXT]: [
          {
            target: ROUTES.PROPERTY_PURCHASE_IN_CONTRACT,
            cond: GUARDS.inContract,
          },
          {
            target: ROUTES.PROPERTY_PURCHASE_NOT_IN_CONTRACT,
          },
        ],
      },
    },
    [ROUTES.CURRENT_ADDRESS_NON_PURCHASE]: {
      on: {
        [ROUTING_EVENTS.NEXT]: ROUTES.PROPERTY_NON_PURCHASE,
      },
    },
    [ROUTES.PROPERTY_PURCHASE_IN_CONTRACT]: {
      on: {
        [ROUTING_EVENTS.NEXT]: [
          {
            target: ROUTES.UNSUPPORTED_MARKET,
            cond: GUARDS.isUnsupportedMarket,
          },
          {
            target: ROUTES.ASSETS,
          },
        ],
      },
    },
    [ROUTES.PROPERTY_PURCHASE_NOT_IN_CONTRACT]: {
      on: {
        [ROUTING_EVENTS.NEXT]: ROUTES.ASSETS,
      },
    },
    [ROUTES.PROPERTY_NON_PURCHASE]: {
      on: {
        [ROUTING_EVENTS.NEXT]: [
          {
            target: ROUTES.UNSUPPORTED_MARKET,
            cond: GUARDS.isUnsupportedMarket,
          },
          {
            target: ROUTES.INCOME,
          },
        ],
      },
    },
    [ROUTES.ASSETS]: {
      on: {
        [ROUTING_EVENTS.NEXT]: ROUTES.INCOME,
      },
    },
    [ROUTES.INCOME]: {
      on: {
        [ROUTING_EVENTS.NEXT]: ROUTES.IDENTITY,
      },
    },
    [ROUTES.IDENTITY]: {
      on: {
        [ROUTING_EVENTS.NEXT]: [
          {
            target: ROUTES.PROCESSING_LEAD_SUBMISSION,
            cond: GUARDS.isHeloc,
          },
          {
            target: ROUTES.PROCESSING_RATES,
          },
        ],
      },
    },
    [ROUTES.PROCESSING_RATES]: {
      on: {
        [ROUTING_EVENTS.NEXT]: [
          {
            target: ROUTES.RATES_SELECT,
            cond: GUARDS.hasRates,
          },
          {
            target: ROUTES.CONFETTI,
          },
        ],
      },
    },
    [ROUTES.RATES_SELECT]: {
      on: {
        [ROUTING_EVENTS.NEXT]: ROUTES.CONFETTI,
      },
    },
    [ROUTES.PROCESSING_LEAD_SUBMISSION]: {
      on: {
        [ROUTING_EVENTS.NEXT]: ROUTES.CONFETTI,
      },
    },
    [ROUTES.CONFETTI]: {},
    [ROUTES.UNSUPPORTED_MARKET]: {},
    [ROUTES.NON_PREAPPROVAL_SUCCESS]: {},
    [ROUTES.ASSET_LINK]: {}, // TODO: We'll incorporate asset link into the flow later.
  },
},
{
  guards: {
    [GUARDS.isHeloc]: (context) => context.applicationType === 'Heloc',
    [GUARDS.isPurchase]: (context) => context.applicationType === 'Purchase',
    [GUARDS.isRefinance]: (context) => context.applicationType === 'Refinance',
    [GUARDS.hasRates]: (context) => context.hasRates,
    [GUARDS.inContract]: (context) => context.borrowerContractStatus === CONTRACT_STATUSES.inContract,
    [GUARDS.isUnsupportedMarket]: (context) => context.isUnsupportedMarket,
  },
});
