import { ColorTokens } from '@lightspeed/styles/primary/tokens/colors';

const Gotham = {
  bold: 'Gotham-Bold, sans-serif',
  book: 'Gotham-Book, sans-serif',
  light: 'Gotham-Light, sans-serif',
  medium: 'Gotham-Medium, sans-serif',
};

const Input = {
  book: {
    color: '#000000',
    fontFamily: Gotham.book,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.04em',
    lineHeight: '16px',
  },
  medium: {
    color: '#000000',
    fontFamily: Gotham.medium,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.04em',
    lineHeight: '16px',
  },
};

const Scoreboard = {
  desktop: {
    color: '#1D1D23',
    fontFamily: Gotham.bold,
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.04em',
  },
  mobile: {
    color: '#1D1D23',
    fontFamily: Gotham.bold,
    fontSize: '44px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: ' -0.01em',
  },
};

const Body = {
  large: {
    color: '#000000',
    fontFamily: Gotham.book,
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '30px',
  },
  largeLight: {
    color: ColorTokens.ash,
    fontFamily: Gotham.book,
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '30px',
  },
  legal: {
    a: {
      color: '#6A6B76',
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
    color: '#6A6B76',
    fontFamily: Gotham.book,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '18px',
    opacity: '0.8',
  },
  light: {
    color: '#707075',
    fontFamily: Gotham.book,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '18px',
  },
  regular: {
    color: '#000000',
    fontFamily: Gotham.book,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '24px',
  },
  small: {
    color: '#000000',
    fontFamily: Gotham.book,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '18px',
  },
  smallLight: {
    color: '#707075',
    fontFamily: Gotham.book,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '18px',
  },
};

const Label = {
  bold: {
    color: '#000000',
    fontFamily: Gotham.bold,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.04em',
    lineHeight: '14px',
  },
  book: {
    color: '#000000',
    fontFamily: Gotham.book,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.04em',
    lineHeight: '14px',
  },
};

const Link = {
  bold: {
    color: '#000000',
    fontFamily: Gotham.bold,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.04em',
    lineHeight: '14px',
  },
  book: {
    color: '#000000',
    fontFamily: Gotham.book,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.04em',
    lineHeight: '14px',
  },
};

const Button = {
  bold: {
    color: '#000000',
    fontFamily: Gotham.bold,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.04em',
    lineHeight: '19px',
  },
  medium: {
    color: '#000000',
    fontFamily: Gotham.medium,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.04em',
    lineHeight: '19px',
  },
};

const Headers = {
  h1: {
    large: {
      desktop: {
        color: '#000000',
        fontFamily: Gotham.bold,
        fontSize: '96px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        letterSpacing: '-0.04em',
        lineHeight: '1.08',
      },
      mobile: {
        color: '#000000',
        fontFamily: Gotham.bold,
        fontSize: '44px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        letterSpacing: '-0.04em',
        lineHeight: '1.27',
      },
    },
    normal: {
      desktop: {
        color: '#000000',
        fontFamily: Gotham.bold,
        fontSize: '60px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        letterSpacing: '-0.04em',
        lineHeight: '72px',
      },
      mobile: {
        color: '#000000',
        fontFamily: Gotham.bold,
        fontSize: '36px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        letterSpacing: '-0.04em',
        lineHeight: '1.17',
      },
    },
  },
  h2: {
    mobile: {
      color: '#000000',
      fontFamily: Gotham.bold,
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.04em',
      lineHeight: '34px',
    },
    tablet: {
      color: '#000000',
      fontFamily: Gotham.bold,
      fontSize: '44px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.04em',
      lineHeight: '53px',
    },
  },
  h3: {
    mobile: {
      color: '#000000',
      fontFamily: Gotham.bold,
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.04em',
      lineHeight: '29px',
    },
    tablet: {
      color: '#000000',
      fontFamily: Gotham.bold,
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.04em',
      lineHeight: '34px',
    },
  },
  h4: {
    mobile: {
      color: '#000000',
      fontFamily: Gotham.bold,
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.04em',
      lineHeight: '24px',
    },
    tablet: {
      color: '#000000',
      fontFamily: Gotham.bold,
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.04em',
      lineHeight: '24px',
    },
  },
  h5: {
    mobile: {
      color: '#000000',
      fontFamily: Gotham.bold,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.04em',
      lineHeight: '19px',
    },
    tablet: {
      color: '#000000',
      fontFamily: Gotham.bold,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.04em',
      lineHeight: '19px',
    },
  },
  h6: {
    mobile: {
      color: '#000000',
      fontFamily: Gotham.bold,
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '14px',
    },
    tablet: {
      color: '#000000',
      fontFamily: Gotham.bold,
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '14px',
    },
  },
};

export const TypographyTokens = {
  Body,
  Button,
  Gotham,
  Headers,
  Input,
  Label,
  Link,
  Scoreboard,
};
