import { CookieAttributes } from 'js-cookie';

export const getCookieOptions = (): CookieAttributes => {
  if (window.location.hostname === 'localhost') { return {}; }

  const hostnameParts = window.location.hostname.split('.');

  return {
    domain: `.${hostnameParts[hostnameParts.length - 2]}.${hostnameParts[hostnameParts.length - 1]}`,
  };
};
