import cookies from 'js-cookie';
import { getCookieOptions } from '@lightspeed/utils/get-cookie-options';

const utmSourceKey = 'utm_source';
const utmCampaignKey = 'utm_campaign';
const utmMediumKey = 'utm_medium';
const utmTermKey = 'utm_term';
const utmContentKey = 'utm_content';

export function removeUtmSource(): void {
  cookies.remove(utmSourceKey, getCookieOptions());
}

export function getUtmSource(): string | null {
  return cookies.get(utmSourceKey) || null;
}

export function setUtmSource(token: string): void {
  cookies.set(utmSourceKey, token, getCookieOptions());
}

export function removeUtmCampaign(): void {
  cookies.remove(utmCampaignKey, getCookieOptions());
}

export function getUtmCampaign(): string | null {
  return cookies.get(utmCampaignKey) || null;
}

export function setUtmCampaign(token: string): void {
  cookies.set(utmCampaignKey, token, getCookieOptions());
}

export function removeUtmMedium(): void {
  cookies.remove(utmMediumKey, getCookieOptions());
}

export function getUtmMedium(): string | null {
  return cookies.get(utmMediumKey) || null;
}

export function setUtmMedium(token: string): void {
  cookies.set(utmMediumKey, token, getCookieOptions());
}

export function removeUtmContent(): void {
  cookies.remove(utmContentKey, getCookieOptions());
}

export function getUtmContent(): string | null {
  return cookies.get(utmContentKey) || null;
}

export function setUtmContent(token: string): void {
  cookies.set(utmContentKey, token, getCookieOptions());
}

export function removeUtmTerm(): void {
  cookies.remove(utmTermKey, getCookieOptions());
}

export function getUtmTerm(): string | null {
  return cookies.get(utmTermKey) || null;
}

export function setUtmTerm(token: string): void {
  cookies.set(utmTermKey, token, getCookieOptions());
}

export function getUtmParams() {
  const url = new URL(window.location.href);

  const queryUtmMedium = url.searchParams.get(utmMediumKey);
  const queryUtmSource = url.searchParams.get(utmSourceKey);
  const queryUtmCampaign = url.searchParams.get(utmCampaignKey);
  const queryUtmContent = url.searchParams.get(utmContentKey);
  const queryUtmTerm = url.searchParams.get(utmTermKey);

  const attributionValues = {
    utmCampaign: queryUtmCampaign || getUtmCampaign(),
    utmContent: queryUtmContent || getUtmContent(),
    utmMedium: queryUtmMedium || getUtmMedium(),
    utmSource: queryUtmSource || getUtmSource(),
    utmTerm: queryUtmTerm || getUtmTerm(),
  };

  if (attributionValues.utmCampaign) {
    setUtmCampaign(attributionValues.utmCampaign);
  }

  if (attributionValues.utmMedium) {
    setUtmMedium(attributionValues.utmMedium);
  }

  if (attributionValues.utmSource) {
    setUtmSource(attributionValues.utmSource);
  }

  if (attributionValues.utmContent) {
    setUtmContent(attributionValues.utmContent);
  }

  if (attributionValues.utmTerm) {
    setUtmTerm(attributionValues.utmTerm);
  }
}
