import cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { getCookieOptions } from '@lightspeed/utils/get-cookie-options';

const key = 'ffrp';

export function removeFriendsAndFamilyReferralProgramEmail(): void {
  cookies.remove(key, getCookieOptions());
}

export function getFriendsAndFamilyReferralProgramEmail(): string | null {
  return cookies.get(key) || null;
}

export function setFriendsAndFamilyReferralProgramEmail(token: string): void {
  cookies.set(key, token, getCookieOptions());
}

function getInitialToken(): string | null {
  const url = new URL(window.location.href);

  const queryEmail = url.searchParams.get(key);

  return queryEmail || getFriendsAndFamilyReferralProgramEmail();
}

export function useFriendsAndFamilyReferralProgramEmail() {
  const [token] = useState<string | null>(() => getInitialToken());

  useEffect(() => {
    const url = new URL(window.location.href);

    const queryEmail = url.searchParams.get(key);

    if (queryEmail) {
      setFriendsAndFamilyReferralProgramEmail(queryEmail);
    }
  }, []);

  return token;
}
