import React from 'react';
import styled from 'styled-components';

export interface ProgressBarProps {
  progress: number;
}

export function ProgressBar({ progress }: ProgressBarProps) {
  return (
    <ProgressBarWrapper>
      <ProgressIndicator
        style={{
          width: `${progress}%`,
        }}
        data-testid={'progress-indicator'}
      />
    </ProgressBarWrapper>
  );
}

const ProgressBarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #EDEDF4;
`;

const ProgressIndicator = styled.div`
  background-color: #001CDB;
  height: 8px;
  transition: 1s ease;
`;
