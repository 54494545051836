import styled from 'styled-components';
import { useEffect } from 'react';
import { useAnalytics } from '@lower/analytics';
import { Heading, Paragraph, Button, LottieSource } from '@lower/core-components';
import { BasePageTemplate   } from '@lightspeed/components/ui/templates';
import { Header } from '@lightspeed/components/ui/molecules/header';
import confettiAnimation from '@lightspeed/assets/lotties/Confetti.json';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { usePersonalizedLoan } from '@lightspeed/contexts/personalized-loan-context/personalized-loan-context';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const urls = {
  Heloc: 'https://www.lower.com/success/heloc',
  Purchase: 'https://www.lower.com/success/finance',
  Refinance: 'https://www.lower.com/success/refinance',
};

export function Confetti() {
  const { mortgageApplication } = useMortgageApplication();
  const fireAnalyticsEvent = useAnalytics('confetti');

  const { personalizedLoanOptions } = usePersonalizedLoan();

  useEffect(() => {
    if (mortgageApplication.fromRateQuery === 'true') {
      fireAnalyticsEvent('application_complete_from_rqw', {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mortgageApplication.applicationType === 'Heloc') {
      return;
    }

    if (personalizedLoanOptions.passedFilters === true) {
      fireAnalyticsEvent('high_quality_lead');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <BasePageTemplate
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <PageWrapper>
          <LottieSource
            lottieJSONFile={confettiAnimation}
            style={{
              marginBottom: '-20vmin',
              marginTop: '-20vmin',
              maxWidth: '60vmin',
              minWidth: '250px',
            }}
            lottieLoop={false}
          />
          <Heading
            element={'h1'}
            style={{
              textAlign: 'center',
            }}
          >We got your application.
          </Heading>
          <Paragraph
            variant={'smallLight'}
            textAlign={'center'}
            fontSize={'16px'}
            marginTop={'24px'}
          >
            We'll take it from here. Expect a call from us shortly we can't wait to help you!
          </Paragraph>
          <Button
            marginY={'24px'}
            style={{
              zIndex: 1,
            }}
            onClick={() => {
              window.open(urls[mortgageApplication.applicationType], '_blank');
            }}
          >
            Learn more
          </Button>
        </PageWrapper>
      </BasePageTemplate>
    </>
  );
}
