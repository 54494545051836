import { ComponentProps } from 'react';
import styled from 'styled-components';
import { color, compose } from 'styled-system';
import type { ColorProps } from 'styled-system';
import { ThemedProps } from '@lower/core-components/src/styles/utils/theme-utils';

type BaseLinkProps = {
  bold?: boolean;
} & ColorProps;

type Props = BaseLinkProps & ThemedProps;

export const Link = styled.a<BaseLinkProps>`
  display: block;
  ${ (props: Props) => props.theme.Link[props.bold ? 'bold' : 'book'] };
  color: ${ (props: Props) => props.theme.colors.blue[0] };
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  ${ compose(color) };
`;

export type LabelProps = ComponentProps<typeof Link>;
