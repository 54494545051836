import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import styled, { css } from 'styled-components';
import { compose, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { responsiveStyleFromTheme, ThemedProps } from '../../styles/utils/theme-utils';

export interface HeadingProps extends HTMLAttributes<HTMLElement> {
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  testId?: string;
  large?: boolean;
}

export const Heading = ({
  className,
  children,
  testId = 'heading',
  element = 'h1',
  large,
  ...otherProps
}: HeadingProps & LayoutProps & SpaceProps): React.ReactElement => (
  <Element
    as={element}
    className={cx(element, className, large && 'is-large')}
    data-test-id={testId}
    {...otherProps}
  >
    {children}
  </Element>
);

const Element = styled.p((props: ThemedProps) => css`
  &.h1 {
    ${ responsiveStyleFromTheme(props.theme.Headers.h1.normal) };
  }

  &.h1.is-large {
    ${ responsiveStyleFromTheme(props.theme.Headers.h1.large) };
  }

  &.h2 {
    ${ responsiveStyleFromTheme(props.theme.Headers.h2) };
  }

  &.h3 {
    ${ responsiveStyleFromTheme(props.theme.Headers.h3) };
  }

  &.h4 {
    ${ responsiveStyleFromTheme(props.theme.Headers.h4) };
  }

  &.h5 {
    ${ responsiveStyleFromTheme(props.theme.Headers.h5) };
  }

  &.h6 {
    ${ responsiveStyleFromTheme(props.theme.Headers.h6) };
  }

  ${
  compose(
    layout,
    space,
  )
}
`);

