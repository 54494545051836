import styled, { ThemedStyledProps } from 'styled-components';
import cx from 'classnames';
import CurrencyBaseInput from 'react-currency-input-field';
import React, { Ref } from 'react';
// eslint is being weird here and not finding the file even though it exists
// eslint-disable-next-line import/no-unresolved
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';
import { ThemeType } from '@lower/core-components/src/styles/primary/theme';
import { TypographyTokens } from '../../styles/primary/tokens/typography';

export interface CurrencyInputProps {
  value: string;
  onChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  placeholder: string;
  allowDecimals?: boolean;
  ref?: Ref<HTMLInputElement>;
  hasError?: boolean;
  name?: string;
}

export function CurrencyInput({
  value,
  onChange,
  placeholder,
  ref,
  allowDecimals = true,
  hasError = false,
  name,
}: CurrencyInputProps) {
  return (
    <MaskedCurrencyInput
      name={name}
      value={value}
      onValueChange={onChange}
      placeholder={placeholder}
      ref={ref}
      prefix={'$'}
      decimalSeparator={'.'}
      groupSeparator={','}
      allowNegativeValue={false}
      allowDecimals={allowDecimals}
      decimalScale={allowDecimals ? 2 : 0}
      decimalsLimit={allowDecimals ? 2 : 0}
      hasError={hasError}
    />
  );
}

type MaskedCurrencyInputProps = CurrencyInputProps & JSX.IntrinsicElements['input'];

// this type is a workaround because the types from .attrs() are not being inferred correctly for some reason
type ThemedProps = ThemedStyledProps<MaskedCurrencyInputProps, ThemeType>;

const MaskedCurrencyInput = styled(({ hasError, ...rest }) => <CurrencyBaseInput {...rest} />).attrs<CurrencyInputProps>((
  {
    className,
    hasError,
    disabled,
    ...props
  }: MaskedCurrencyInputProps,
) => ({
  className: cx(className, 'TextInput', {
    'has-error': hasError,
    'is-disabled': disabled,
  }),
  disabled,
  ...props,
}))`
  all: unset;
  font-family: ${ TypographyTokens.Gotham.book };
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.04em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
  /*min-width: 248px;
  width: auto;*/
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E5E6EE;
  box-sizing: border-box;
  border-radius: 12px;
  transition: border ${(props: ThemedProps) => props.theme.transitions.prop};
  color: #1D1D23;
  &.has-error {
    border: 1px solid ${(props: ThemedProps) => props.theme.colors.error};
  }
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #C4C6D1;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #C4C6D1;
  }
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #C4C6D1;
  }
`;
