
export const ColorTokens = {
  ash: '#6A6B76',
  blue: [
    '#001cdb',
    'rgba(0, 28, 219, 0.1)',
  ],
  brightBlue: '#0064FF',
  chalk: '#F4F4F8',
  charcoal: '#1D1D23',
  disabledText: '#C4C6D1',
  error: '#E44258',
  gray: [
    '#bebebe',
    '#eeeeee',
  ],
  lightBlue: '#2f49ff',
  orange: [
    '#fd7a40',
  ],
  red: '#ff0000',
  satin: '#C4C6D1',
  silk: '#E5E6EE',
  white: [
    '#FFFFFF',
    'rgba(255, 255, 255, 0.35)',
  ],
};

