import { useMediaQuery } from 'react-responsive';
import { theme } from '@lightspeed/styles/primary/theme';

export function Page({ desktop, mobile }: { desktop: JSX.Element, mobile: JSX.Element }) {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints[0]})`,
  });

  return isDesktop ? desktop : mobile;
}
