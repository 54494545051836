import { datadogRum } from '@datadog/browser-rum';
import { ENV } from '@lightspeed/environment';

datadogRum.init({
  allowedTracingOrigins: [ENV.API_HOST, ENV.NEW_BACKEND_API_HOST],
  applicationId: ENV.DATADOG_APPLICATION_ID || '',
  clientToken: ENV.DATADOG_CLIENT_TOKEN || '',
  defaultPrivacyLevel: 'mask-user-input',
  env: ENV.DATADOG_ENV,
  sampleRate: ENV.DATADOG_SAMPLE_RATE,
  service: 'lightspeed',
  site: 'us3.datadoghq.com',
  trackInteractions: true,
  version: ENV.FRONTEND_SHA,
});

datadogRum.startSessionReplayRecording();

window.gtag('set', {
  dimension1: datadogRum.getInternalContext()?.session_id,
});
