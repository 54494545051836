import styled, { css } from 'styled-components';
import cx from 'classnames';
import React, { ChangeEvent } from 'react';
import { ThemedProps } from '../../styles/utils/theme-utils';

export type RadioProps = {
  className?: string;
  id: string;
  isSelected: boolean;
  labelText?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  required?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const Radio = ({
  required = false,
  id,
  labelText,
  onChange,
  name,
  value,
  isSelected,
  className,
  ...props
}: RadioProps) => (
  <Label
    className={cx(className)}
    htmlFor={id}
    {...props}
  >
    <RadioButton
      id={id}
      type={'radio'}
      name={name}
      value={value}
      checked={isSelected}
      onChange={onChange}
    />
    <LabelText>{labelText}</LabelText>
  </Label>
);

const Label = styled.label`
  padding: 0 0 20px;

  ${(props: ThemedProps) => props.theme.media.desktop} {
    padding: unset;
  }
`;

const RadioButton = styled.input`
  appearance: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid ${(props: ThemedProps) => props.theme.colors.gray[0]};
  cursor: pointer;
  vertical-align: middle;
  &:checked {
    border: 5px solid ${(props: ThemedProps) => props.theme.colors.blue[0]};
  }

  ${(props: ThemedProps & JSX.IntrinsicElements['input']) => !props.checked && css`
    &:hover {
      background: ${props.theme.colors.gray[0]};
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin: 2px;
        background: ${props.theme.colors.gray[1]};
      }
    }
  `}
`;

const LabelText = styled.span`
  ${(props: ThemedProps) => props.theme.Label.book};
  padding-left: 8px;
  vertical-align: bottom;
`;
