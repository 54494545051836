/* tslint:disable */
/* eslint-disable */
/**
 * Lower API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Term {
    Fifteen = 'fifteen',
    Twenty = 'twenty',
    Thirty = 'thirty'
}

export function TermFromJSON(json: any): Term {
    return TermFromJSONTyped(json, false);
}

export function TermFromJSONTyped(json: any, ignoreDiscriminator: boolean): Term {
    return json as Term;
}

export function TermToJSON(value?: Term | null): any {
    return value as any;
}

