import React from 'react';
import { Label, TextInput, Select } from '@lower/core-components';
import { ValidationError } from '@lightspeed/components/ui/molecules/validation-error/validation-error';
import { FormRow } from '@lightspeed/components/ui/templates/form/form';
import {
  MortgageApplicationStore,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { STATE_OPTIONS } from '@lightspeed/models/us-states';

interface FullPropertyAddressFormProps {
  mortgageApplication: MortgageApplicationStore;
  updateMortgageApplication: (v: keyof MortgageApplicationStore, z: string) => void;
  errors: Map<string, string[]>|null;
}

export function FullPropertyAddressForm({
  mortgageApplication, updateMortgageApplication, errors,
}: FullPropertyAddressFormProps) {
  return (
    <>
      <FormRow>
        <Label flex={1}>
          Address*
          <TextInput
            name={'propertyStreetAddress'}
            value={mortgageApplication.propertyStreetAddress}
            onChange={(e) => { updateMortgageApplication('propertyStreetAddress', e.target.value); }}
            placeholder={'123 Main St.'}
          />
          <ValidationError
            path={'propertyStreetAddress'}
            errors={errors}
          />
        </Label>
        <Label flex={1}>
          City*
          <TextInput
            name={'propertyCity'}
            value={mortgageApplication.propertyCity}
            onChange={(e) => { updateMortgageApplication('propertyCity', e.target.value); }}
            placeholder={'e.g. Columbus'}
          />
          <ValidationError
            path={'propertyCity'}
            errors={errors}
          />
        </Label>
      </FormRow>
      <FormRow>
        <Label flex={['0 0 100%', 1]}>
          State*
          <Select
            name={'property-state'}
            options={[{
              label: 'Select One',
              value: '',
            }, ...STATE_OPTIONS]}
            value={mortgageApplication.propertyState}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('propertyState', e.target.value)}
          />
          <ValidationError
            path={'propertyState'}
            errors={errors}
          />
        </Label>
        <Label flex={1}>
          Zip Code*
          <TextInput
            inputMode={'numeric'}
            name={'propertyZipCode'}
            value={mortgageApplication.propertyZipCode}
            onChange={(e) => { updateMortgageApplication('propertyZipCode', e.target.value); }}
            placeholder={'43004'}
          />
          <ValidationError
            path={'propertyZipCode'}
            errors={errors}
          />
        </Label>
      </FormRow>
    </>
  );
}
