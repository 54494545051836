import cx from 'classnames';
import styled from 'styled-components';
import { ReactNode } from 'react';
import { ThemedProps } from '../../styles/utils/theme-utils';

export interface ErrorProps {
  isHidden?: boolean;
  className?: string;
  children?: ReactNode;
}

export const Error = styled.p.attrs(
  ({
    isHidden,
    className,
    ...props
  }: ErrorProps) => ({
    className: cx(className, 'Error',
      {
        'is-hidden': isHidden,
      }),
    ...props,
  }),
)<ErrorProps>`
  opacity: 1;
  margin: 0;
  ${ (props: ThemedProps) => props.theme.Label.book };
  color: ${ (props: ThemedProps) => props.theme.colors.error};
  transition: opacity ${(props: ThemedProps) => props.theme.transitions.prop};

  &.is-hidden {
    opacity: 0;
  }
`;
