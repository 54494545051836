import { createContext, ReactNode, useContext } from 'react';
import { usePersonalizedLoanState, PersonalizedLoanState, PersonalizedLoanStore } from './use-personalized-loan-state';

const PersonalizedLoanContext = createContext<PersonalizedLoanState>({
  personalizedLoanOptions: {
    loanProducts: [],
    passedFilters: false,
  },
  updatePersonalizedLoan: () => {
    throw new Error('updatePersonalizedLoan cannot be used before it is set');
  },
});

export function PersonalizedLoanContextProvider({ children, overrides }: { children?: ReactNode|ReactNode[], overrides: Partial<PersonalizedLoanStore> }) {
  return (
    <PersonalizedLoanContext.Provider value={usePersonalizedLoanState(overrides)}>
      {children}
    </PersonalizedLoanContext.Provider>
  );
}

export function usePersonalizedLoan() {
  return useContext(PersonalizedLoanContext);
}
