import React from 'react';
import { Button, Heading, Label, OptionalElement, PhoneInput, Select, TextInput } from '@lower/core-components';
import { ValidationError } from '@lightspeed/components/ui/molecules/validation-error/validation-error';
import { Form, FormRow } from '@lightspeed/components/ui/templates/form/form';
import Plus from '@lightspeed/assets/icons/Plus.svg';
import {
  MortgageApplicationStore,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { STATE_OPTIONS } from '@lightspeed/models/us-states';

interface BasicInfoFormProps {
  mortgageApplication: MortgageApplicationStore;
  updateMortgageApplication: (v: keyof MortgageApplicationStore, z: string) => void;
  errors: Map<string, string[]>|null;
  hasCoborrower: boolean;
  setHasCoborrower: React.Dispatch<React.SetStateAction<boolean>>;
}

export function BasicInfoForm({
  mortgageApplication, updateMortgageApplication, errors, hasCoborrower, setHasCoborrower,
}: BasicInfoFormProps) {
  const coBorrowerLabelControl = hasCoborrower ? ' Remove Co-borrower' : ' Add Co-borrower';
  return (
    <>
      <Form>
        <Heading
          element={'h4'}
        >
          Basic Info
        </Heading>
        <FormRow>
          <Label flex={1}>
            First Name*
            <TextInput
              name={'borrowerFirstName'}
              value={mortgageApplication.borrowerFirstName}
              onChange={(e) => { updateMortgageApplication('borrowerFirstName', e.target.value); }}
            />
            <ValidationError
              path={'borrowerFirstName'}
              errors={errors}
            />
          </Label>
          <Label flex={1}>
            Last Name*
            <TextInput
              name={'borrowerLastName'}
              value={mortgageApplication.borrowerLastName}
              onChange={(e) => { updateMortgageApplication('borrowerLastName', e.target.value); }}
            />
            <ValidationError
              path={'borrowerLastName'}
              errors={errors}
            />
          </Label>
        </FormRow>
        <FormRow>
          <Label flex={['0 0 100%', 1]}>
            Email*
            <TextInput
              $hasError={errors?.get('borrowerEmail') != null}
              inputMode={'email'}
              name={'borrowerEmail'}
              value={mortgageApplication.borrowerEmail}
              onChange={(e) => { updateMortgageApplication('borrowerEmail', e.target.value); }}
              placeholder={'me@example.com'}
            />
            <ValidationError
              path={'borrowerEmail'}
              errors={errors}
            />
          </Label>
          <Label flex={['0 0 100%', 1]}>
            Phone*
            <PhoneInput
              name={'borrower-phone-number'}
              value={mortgageApplication.borrowerPhoneNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateMortgageApplication('borrowerPhoneNumber', e.target.value)}
            />
            <ValidationError
              path={'borrowerPhoneNumber'}
              errors={errors}
            />
          </Label>
        </FormRow>
      </Form>
      <OptionalElement show={hasCoborrower}>
        <Form>
          <Heading
            element={'h4'}
          >
            Co-borrower Basic Info
          </Heading>
          <FormRow>
            <Label flex={1}>
              Co-borrower First Name*
              <TextInput
                name={'coBorrowerFirstName'}
                value={mortgageApplication.coBorrowerFirstName}
                onChange={(e) => { updateMortgageApplication('coBorrowerFirstName', e.target.value); }}
              />
              <ValidationError
                path={'coBorrowerFirstName'}
                errors={errors}
              />
            </Label>
            <Label flex={1}>
              Co-borrower Last Name*
              <TextInput
                name={'coBorrowerLastName'}
                value={mortgageApplication.coBorrowerLastName}
                onChange={(e) => { updateMortgageApplication('coBorrowerLastName', e.target.value); }}
              />
              <ValidationError
                path={'coBorrowerLastName'}
                errors={errors}
              />
            </Label>
          </FormRow>
          <FormRow>
            <Label flex={['0 0 100%', 1]}>
              Co-borrower Email*
              <TextInput
                inputMode={'email'}
                name={'coBorrowerEmail'}
                value={mortgageApplication.coBorrowerEmail}
                onChange={(e) => { updateMortgageApplication('coBorrowerEmail', e.target.value); }}
                placeholder={'me@example.com'}
              />
              <ValidationError
                path={'coBorrowerEmail'}
                errors={errors}
              />
            </Label>
            <Label flex={['0 0 100%', 1]}>
              Co-borrower Phone*
              <PhoneInput
                name={'coborrower-phone-number'}
                value={mortgageApplication.coBorrowerPhoneNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateMortgageApplication('coBorrowerPhoneNumber', e.target.value)}
              />
              <ValidationError
                path={'coBorrowerPhoneNumber'}
                errors={errors}
              />
            </Label>
          </FormRow>
        </Form>
      </OptionalElement>
      <Button
        aria-label={`${coBorrowerLabelControl.trim()}`}
        variant={'link'}
        onClick={() => {
          updateMortgageApplication('coBorrowerFirstName', '');
          updateMortgageApplication('coBorrowerLastName', '');
          updateMortgageApplication('coBorrowerEmail', '');
          updateMortgageApplication('coBorrowerPhoneNumber', '');
          setHasCoborrower((has) => !has);
        }}
      >
        <img
          alt={'icon'}
          style={hasCoborrower ? {
            transform: 'rotate(45deg)',
          } : {}}
          src={Plus}
        />
        {coBorrowerLabelControl}
      </Button>
      <Form
        style={{
          paddingTop: 48,
        }}
      >
        <Heading
          element={'h4'}
        >
          Where is the property located?
        </Heading>
        <FormRow>
          <Label
            flex={1}
          >
            City*
            <TextInput
              name={'propertyCity'}
              aria-label={'City'}
              value={mortgageApplication.propertyCity}
              onChange={(e) => { updateMortgageApplication('propertyCity', e.target.value); }}
              placeholder={'e.g. Columbus'}
            />
            <ValidationError
              path={'propertyCity'}
              errors={errors}
            />
          </Label>
          <Label
            flex={1}
          >
            State*
            <Select
              label={'State'}
              name={'property-state'}
              options={[{
                label: 'Select One',
                value: '',
              }, ...STATE_OPTIONS]}
              value={mortgageApplication.propertyState}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('propertyState', e.target.value)}
            />
            <ValidationError
              path={'propertyState'}
              errors={errors}
            />
          </Label>
        </FormRow>
      </Form>
    </>
  );
}
