import styled from 'styled-components';
import { responsiveStyleFromTheme } from '@lightspeed/styles/utils/theme-utils';
import AmbientBlueBackground from './ambient-blue-background.svg';

interface ProcessingTemplateProps {
  progress: number;
  prompt: string;
}

export function ProcessingTemplate({ progress, prompt }: ProcessingTemplateProps) {
  return (
    <Background>
      <ProgressText>
        {prompt}
      </ProgressText>
      <ProgressBar>
        <ProgressBarInner
          style={{
            width: `${progress}%`,
          }}
        />
      </ProgressBar>
    </Background>
  );
}

const ProgressBar = styled.div`
  width: 80vmin;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  bottom: 60px;
  ${responsiveStyleFromTheme({
    desktop: {
      bottom: 0,
      position: 'relative',
    },
  })}
`;

const ProgressBarInner = styled.div`
  height: 3px;
  background-color: #ffffff;
  transition: width 80ms;
`;

const Background = styled.div`
  position: absolute;
  inset: 0;
  background: url(${AmbientBlueBackground});
  background-size: cover;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ProgressText = styled.p`
  color: #fff;
  font-size: 20px;
  line-height: 30px;
`;
