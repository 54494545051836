import { Scoreboard } from '@lightspeed/components/ui/molecules/scoreboard/scoreboard';
import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { useRate } from '@lightspeed/hooks/useRate';

declare global {
  interface String {
    toLowerCase<T extends string>(this: T): Lowercase<T>;
  }
}
export function TodaysRates({ mortgageApplication }: { mortgageApplication: MortgageApplicationStore }) {
  const {
    rate, apr, monthlyPayment, loanAmount, discountPoints, ltv,
  } = useRate(mortgageApplication.loanTerm, mortgageApplication.applicationType.toLowerCase());

  return (
    <Scoreboard
      tooltip={`Interest rate based on a ${loanAmount} loan amount with a ${mortgageApplication.loanTerm}-year fixed rate and ${apr} APR and ${monthlyPayment} monthly payment at ${ltv}% loan-to-value (LTV) and ${discountPoints} discount points.`}
      marginY={'36px'}
      label={'Today\'s Rate'}
      suffix={'%'}
      value={rate}
    />
  );
}
