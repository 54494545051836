import {  ErrorResponse, QuotingResponse } from '@lower-web-api/generated';
import { ENV } from '@lightspeed/environment';
import { EmptyResponse, fetch, TypedResponse } from '@lightspeed/utils/fetch';

export function getQuotingApplicationQuotes(id: string): Promise<GetQuotingApplicationQuoteResponse> {
  return fetch<GetQuotingApplicationQuoteResponse>(
    `${ENV.NEW_BACKEND_API_HOST}/api/lending/quoting_application_submissions/${id}/quotes`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    },
  );
}
type NotFoundErrorResponseInterface = TypedResponse<ErrorResponse, 404>;

export type GetQuotingApplicationQuoteResponse =
  | NotFoundErrorResponseInterface
  | TypedResponse<QuotingResponse, 200>
  | EmptyResponse<204>;
