/* tslint:disable */
/* eslint-disable */
/**
 * Lower API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanPurpose {
    Purchase = 'purchase',
    Refinance = 'refinance',
    Heloc = 'heloc'
}

export function LoanPurposeFromJSON(json: any): LoanPurpose {
    return LoanPurposeFromJSONTyped(json, false);
}

export function LoanPurposeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoanPurpose {
    return json as LoanPurpose;
}

export function LoanPurposeToJSON(value?: LoanPurpose | null): any {
    return value as any;
}

